export const getCards = (
  addresses,
  relatives,
  phones,
  emails,
  isFreeScan = false,
  action = null
) => {
  const cards = [];
  if (addresses.length) {
    cards.push(addresses);
  }
  if (relatives.length) {
    cards.push(relatives);
  }
  if (phones.length) {
    cards.push(phones);
  }
  if (emails.length) {
    cards.push(emails);
  }
  if (isFreeScan) {
    cards.push({ type: 'cta', action });
  }
  return cards;
};
