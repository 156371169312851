import Button from 'react-bootstrap/Button';
import { ErrorMessage } from '@hookform/error-message';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import React from 'react';
import { useDocumentTitle } from '../hooks/useDocumentTitle';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Alert, Col, Row } from 'react-bootstrap';
import {
  getLocalAnonymousProfileData,
  useCreateAnonymousProfileMutation,
  useCreateAnonymousScanMutation,
} from '../redux/api/anonymousProfile';

const StartForm = ({ duringFreeScan = false }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm();
  const navigate = useNavigate();

  const [createProfile, { isLoading: isCreatingProfile }] =
    useCreateAnonymousProfileMutation();

  const [createScan, { isSuccess: isScanCreated }] =
    useCreateAnonymousScanMutation();

  const profileData = getLocalAnonymousProfileData();

  const submitForm = async (data) => {
    const response = await createProfile(data);

    if (response?.error) {
      const onRepErrors = response?.error?.data?.errors;

      if (onRepErrors) {
        for (const [fieldName, message] of Object.entries(onRepErrors)) {
          setError(fieldName, { message });
        }
      } else if (response?.error?.data?.message) {
        let errorMessages = '';
        if (Array.isArray(response?.error?.data?.message)) {
          errorMessages = [
            ...new Set(response?.error?.data?.message.flat()),
          ].join('\n');
        } else {
          errorMessages = response?.error?.data?.message;
        }

        setError('firstName', {
          type: 'custom',
          message: errorMessages,
        });
      }
    } else {
      createScan(response.data.id);

      navigate('/free-scan');
    }
  };

  useEffect(() => {
    if (isScanCreated) {
      navigate('/free-scan');
    }
  }, [isScanCreated, navigate]);

  useDocumentTitle('Get started');

  return (
    <Form onSubmit={handleSubmit(submitForm)} className="m-3">
      <Row>
        <Col>
          <Form.Group>
            <ErrorMessage
              errors={errors}
              name={'firstName'}
              render={({ message }) => (
                <Alert variant={'danger'}>{message}</Alert>
              )}
            />

            <FloatingLabel label="First Name" className="mb-3">
              <Form.Control
                {...register('firstName', {
                  required: 'First Name is required',
                  value: profileData?.firstName,
                })}
                placeholder="First Name"
              />
            </FloatingLabel>
            <ErrorMessage
              errors={errors}
              name={'lastName'}
              render={({ message }) => (
                <Alert variant={'danger'}>{message}</Alert>
              )}
            />

            <FloatingLabel label="Last Name" className="mb-3">
              <Form.Control
                {...register('lastName', {
                  required: 'Last Name is required',
                  value: profileData?.lastName,
                })}
                placeholder="Last Name"
              />
            </FloatingLabel>
          </Form.Group>

          <Form.Group>
            <ErrorMessage
              errors={errors}
              name={'addresses[0].city'}
              render={({ message }) => (
                <Alert variant={'danger'}>{message}</Alert>
              )}
            />

            <FloatingLabel label="City" className="mb-3">
              <Form.Control
                {...register('addresses[0].city', {
                  required: 'City is required',
                  value: profileData?.addresses[0]?.city,
                })}
                placeholder="City"
              />
            </FloatingLabel>
          </Form.Group>

          <Form.Group>
            <ErrorMessage
              errors={errors}
              name={'addresses[0].state'}
              render={({ message }) => (
                <Alert variant={'danger'}>{message}</Alert>
              )}
            />

            <FloatingLabel label="State" className="mb-3">
              <Form.Select
                aria-label="State"
                {...register('addresses[0].state', {
                  required: 'State is required',
                  value: profileData?.addresses[0]?.state,
                })}
                placeholder="State">
                <option value="AL">Alabama</option>
                <option value="AK">Alaska</option>
                <option value="AZ">Arizona</option>
                <option value="AR">Arkansas</option>
                <option value="CA">California</option>
                <option value="CO">Colorado</option>
                <option value="CT">Connecticut</option>
                <option value="DE">Delaware</option>
                <option value="DC">District of Columbia</option>
                <option value="FL">Florida</option>
                <option value="GA">Georgia</option>
                <option value="HI">Hawaii</option>
                <option value="ID">Idaho</option>
                <option value="IL">Illinois</option>
                <option value="IN">Indiana</option>
                <option value="IA">Iowa</option>
                <option value="KS">Kansas</option>
                <option value="KY">Kentucky</option>
                <option value="LA">Louisiana</option>
                <option value="ME">Maine</option>
                <option value="MD">Maryland</option>
                <option value="MA">Massachusetts</option>
                <option value="MI">Michigan</option>
                <option value="MN">Minnesota</option>
                <option value="MS">Mississippi</option>
                <option value="MO">Missouri</option>
                <option value="MT">Montana</option>
                <option value="NE">Nebraska</option>
                <option value="NV">Nevada</option>
                <option value="NH">New Hampshire</option>
                <option value="NJ">New Jersey</option>
                <option value="NM">New Mexico</option>
                <option value="NY">New York</option>
                <option value="NC">North Carolina</option>
                <option value="ND">North Dakota</option>
                <option value="OH">Ohio</option>
                <option value="OK">Oklahoma</option>
                <option value="OR">Oregon</option>
                <option value="PA">Pennsylvania</option>
                <option value="RI">Rhode Island</option>
                <option value="SC">South Carolina</option>
                <option value="SD">South Dakota</option>
                <option value="TN">Tennessee</option>
                <option value="TX">Texas</option>
                <option value="UT">Utah</option>
                <option value="VT">Vermont</option>
                <option value="VA">Virginia</option>
                <option value="WA">Washington</option>
                <option value="WV">West Virginia</option>
                <option value="WI">Wisconsin</option>
                <option value="WY">Wyoming</option>
              </Form.Select>
            </FloatingLabel>
          </Form.Group>
          <Form.Group>
            <Button
              variant="primary"
              type="submit"
              disabled={isCreatingProfile}>
              {duringFreeScan === true ? 'Continue' : 'Start My Free Scan!'}
            </Button>
          </Form.Group>
        </Col>
      </Row>
    </Form>
  );
};

export default StartForm;
