import { profileSections } from './profileSections';

// The Profile Update API endpoint takes less fields
// than what the Profile Get API endpoint returns.
// This function takes the returns the Profile as
// JSON in the format the Update endpoint accepts.
export const profileJsonForUpdate = (profileData) => {
  const profileInJson = {
    firstName: profileData?.firstName,
    lastName: profileData?.lastName,
    middleName: profileData?.middleName,
  };

  profileSections.forEach((section) => {
    if (Array.isArray(profileData?.[section.field]) === true) {
      profileInJson[section.field] = [];
      profileData?.[section.field].forEach((item) => {
        const newItemData = {};

        section?.inputFields?.forEach((inputField) => {
          newItemData[inputField.field] = item?.[inputField.field];
        });

        profileInJson[section.field].push(newItemData);
      });
    } else {
      profileInJson[section.field] = profileData?.[section.field];
    }
  });

  return profileInJson;
};
