import React from 'react';
import toTitleCase from '../app/lib/toTitleCase';
import { useDocumentTitle } from '../hooks/useDocumentTitle';
import { useGetCurrentUserQuery } from '../redux/api/authApi';
import { useGetProfileQuery } from '../redux/api/profileApi';
import { useInterval } from '../hooks/useInterval';
import { useNavigate } from 'react-router-dom';
import { Button, Col, Row, Spinner } from 'react-bootstrap';
import {
  useGetProfileCurrentStatsQuery,
  useGetScansQuery,
} from '../redux/api/profileApi';

const FilteringResultsScreen = () => {
  const navigate = useNavigate();

  const { data: currentUser, isLoading: isLoadingUser } =
    useGetCurrentUserQuery();

  const profileId = currentUser?.externalProfileIds?.[0];

  const {
    data: profileScans,
    isLoading: isLoadingScans,
    refetch: refetchScans,
  } = useGetScansQuery(profileId);

  const { data: profileData, isLoading: isLoadingProfileData } =
    useGetProfileQuery(profileId);

  const {
    data: currentStats,
    isLoading: isLoadingStats,
    refetch: refetchStats,
  } = useGetProfileCurrentStatsQuery(profileId);

  useDocumentTitle('Filtering Results');

  useInterval(
    () => {
      refetchScans();
    },
    profileScans?.[0]?.status === 'finished' ? null : 100
  );

  useInterval(
    () => {
      refetchStats();
    },
    profileScans?.[0]?.status === 'finished' ? null : 100
  );

  if (
    isLoadingProfileData ||
    isLoadingUser ||
    isLoadingScans ||
    isLoadingStats
  ) {
    return (
      <Spinner animation="border">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    );
  }

  return (
    <React.Fragment>
      <Row>
        <Col md={{ span: 8, offset: 2 }}>
          {profileScans?.[0]?.status === 'finished' && (
            <React.Fragment>
              <h1>
                <strong>{currentStats.total}</strong> records found for{' '}
                <span className={'d-block'}>
                  {`${toTitleCase(profileData.firstName)} ${toTitleCase(
                    profileData.lastName
                  )}, ${profileData.addresses[0].city} ${
                    profileData.addresses[0].state
                  }`}
                </span>
              </h1>
            </React.Fragment>
          )}
          {profileScans?.[0]?.status !== 'finished' && (
            <React.Fragment>
              <h1>Filtering your results...</h1>
              <p>
                We are doing a new scan with the additional profile information.
                This can take several minutes.
              </p>
              <Spinner animation="grow">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
              <p>
                <strong>{currentStats.total}</strong> records found
              </p>
            </React.Fragment>
          )}
          {profileScans?.[0]?.status === 'finished' && (
            <React.Fragment>
              <div className={'sign-up-cta'}>
                <h2>Your InfoRemoval has begun!</h2>
                <p>
                  You can go to the dashboard to see the progress and details.
                </p>
                <p>
                  We will alert you on the first removal and send a monthly
                  update. There is nothing else you need to do.
                </p>
              </div>
              <Button variant="primary" onClick={() => navigate('/dashboard')}>
                Dashboard
              </Button>
            </React.Fragment>
          )}
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default FilteringResultsScreen;
