import { Button } from 'react-bootstrap';
import { ExternalLink } from 'lucide-react';
import React from 'react';
import toTitleCase from '../app/lib/toTitleCase';
import { useGetCurrentUserQuery } from '../redux/api/authApi';
// import { AtSign, ExternalLink, MapPin, Phone, Users } from 'lucide-react';

const ScanResultCard = ({ scanResult }) => {
  // Icons were hidden at the request of Cooper.
  // const iconTypes = [
  //   { type: 'emails', svg: AtSign },
  //   { type: 'phones', svg: Phone },
  //   { type: 'relatives', svg: Users },
  // ];

  const cardOnClick = (e, scanResult) => {
    e.preventDefault();
    window.open(scanResult.link, '_blank');
  };

  const { data: currentUser, isLoading: isLoadingUser } =
    useGetCurrentUserQuery();

  if (isLoadingUser) {
    return null;
  }

  const userIsTrialing = currentUser?.subscriptionStatus === 'trialing';

  const statusMap = {
    new: {
      label: userIsTrialing ? 'In Progress' : 'New',
      key: 'new',
    },
    removed: {
      label: 'Removed',
      key: 'removed',
    },
    optout_in_progress: {
      label: 'In Progress',
      key: 'in-progress',
    },
    waiting_for_verification: {
      label: 'In Progress',
      key: 'in-progress',
    },
  };

  return (
    <tr className={'section-types-record'}>
      <td width="40%" aria-label={'Data Broker'}>
        {scanResult.dataBroker}
      </td>
      <td width="25%">
        {scanResult.addresses[0] && (
          <React.Fragment>
            {toTitleCase(scanResult.addresses[0].city)},{' '}
            {scanResult.addresses[0].state.toUpperCase()}
          </React.Fragment>
        )}
      </td>
      {/* <td>
        <div className="icon-types">
          <div className={'icon-types-item has-tooltip'}>
            <MapPin color={'grey'}></MapPin>
            <span className="count">{scanResult['addresses'].length}</span>
            <div className={'tooltip'}>
              <p>Locations</p>
              <ul>
                {scanResult['addresses'].map((n, i) => (
                  <li key={i}>
                    {`${toTitleCase(n.city)}, ${n.state.toUpperCase()}`}
                  </li>
                ))}
              </ul>
            </div>
          </div>
          {iconTypes
            .filter((n) => scanResult[n.type].length > 0)
            .map((n, i) => (
              <div key={i} className={'icon-types-item has-tooltip'}>
                <n.svg color={'grey'}></n.svg>
                <span className="count">{scanResult[n.type].length}</span>
                <div className={'tooltip'}>
                  <p>{toTitleCase(n.type)}</p>
                  <ul>
                    {scanResult[n.type].map((n, i) => (
                      <li key={i}>{n}</li>
                    ))}
                  </ul>
                </div>
              </div>
            ))}
        </div>
      </td> */}
      <td width="30%">
        <span
          className={`status-badge status-${statusMap[scanResult.status].key}`}
          aria-label={'Removal Status'}>
          {statusMap[scanResult.status].label}
        </span>
      </td>
      <td width="5%">
        <Button
          tabIndex={1}
          onClick={(e) => {
            cardOnClick(e, scanResult);
          }}
          aria-label={'Link to Data Broker site'}>
          <ExternalLink />
        </Button>
      </td>
    </tr>
  );
};

export default ScanResultCard;
