import Map from '../screens/dashboard/Map';
import React from 'react';
import Reporting from './dashboard/Reporting';
import { ShieldAlert } from 'lucide-react';
import { getAddresses } from '../app/lib/getAddresses';
import toTitleCase from '../app/lib/toTitleCase';
import { useCreateCheckoutSessionMutation } from '../redux/api/stripeApi';
import { useDocumentTitle } from '../hooks/useDocumentTitle';
import { useEffect } from 'react';
import { useGetCurrentUserQuery } from '../redux/api/authApi';
import { Button, Col, Row, Spinner } from 'react-bootstrap';
import {
  useGetProfileCurrentStatsQuery,
  useGetProfileQuery,
  useGetScanResultsQuery,
} from '../redux/api/profileApi';
import { useLocation, useNavigate } from 'react-router-dom';

const DashboardScreen = () => {
  const { data: currentUser, isLoading: isLoadingUser } =
    useGetCurrentUserQuery();

  const navigate = useNavigate();
  const location = useLocation();
  const isDashboardIndex = location?.pathname === '/dashboard';

  const title = isDashboardIndex ? 'Dashboard' : 'Reporting';
  useDocumentTitle(title);

  useEffect(() => {
    if (
      !currentUser?.externalProfileIds?.[0] &&
      currentUser?.anonymousProfileId &&
      currentUser?.hasActiveSubscription
    ) {
      navigate('/complete-profile');
    }
  }, [
    currentUser?.externalProfileIds,
    currentUser?.anonymousProfileId,
    currentUser?.hasActiveSubscription,
    navigate,
  ]);

  const profileId =
    currentUser?.externalProfileIds?.[0] || currentUser?.anonymousProfileId;

  const { data: currentStats, isSuccess: isStatsLoaded } =
    useGetProfileCurrentStatsQuery(profileId);

  const { data: currentProfile } = useGetProfileQuery(profileId);

  const { data: scanResults = [], isLoading: _isLoadingScans } =
    useGetScanResultsQuery(profileId);

  // console.log('Dashboard profileId:', profileId);
  // console.log('scanResults:', scanResults);

  const statsTypes = [
    {
      key: 'new',
      text: 'New',
      tooltip: 'The number of newly found records that have yet to be removed',
    },
    {
      key: 'totalInProgress',
      text: 'In Progress',
      tooltip: 'The number of records that are in progress being removed',
    },
    {
      key: 'removed',
      text: 'Removed',
      tooltip: 'The number of records that have been removed',
    },
    {
      key: 'total',
      text: 'Total',
      tooltip: 'The total number of records found and removed',
    },
  ];

  const [
    createCheckoutSession,
    { isLoading: isLoadingSession, isSuccess: isSessionCreated },
  ] = useCreateCheckoutSessionMutation();

  const gotoSignup = async (e) => {
    e.preventDefault();
    // localStorage.setItem('COUPON_CODE', 'NA3zUNSj');
    const couponCode = localStorage.getItem('COUPON_CODE');
    const checkoutSession = await createCheckoutSession(couponCode);
    window.location.assign(checkoutSession.data.stripeSessionUrl);
    localStorage.removeItem('COUPON_CODE');
  };

  const addresses = getAddresses(scanResults);

  const centerSpinner = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '70vh', // Ensure the spinner covers the entire viewport height
  };

  if (isLoadingUser || !isStatsLoaded) {
    return (
      <div style={centerSpinner}>
        <Spinner animation="border">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

  // 20230627 - If the user has a paying subscription,
  // only show the reporting screen, do not show the map section

  return currentUser?.hasActiveSubscription ? (
    <Reporting profileId={profileId} statsTypes={statsTypes} />
  ) : (
    <React.Fragment>
      <Map addresses={addresses} />
      <Row>
        <Col md={{ span: 4 }}>
          <div className={'dashboard-intro-container'}>
            <h1>
              Welcome back{' '}
              <strong>{toTitleCase(currentProfile?.firstName)}</strong>,
              <br />
              <strong>{currentStats.total}</strong> records found <br /> and
              <strong> {currentStats.removed}</strong> removed
            </h1>
            {currentUser?.hasActiveSubscription && (
              <p className={'sub-heading'}>
                We are actively monitoring and removing your private
                information.
              </p>
            )}

            {!currentUser?.hasActiveSubscription && (
              <React.Fragment>
                <p className={'sub-heading'}>
                  Start your subscription to activate monitoring and removal of
                  your private information.
                </p>

                <Button
                  variant="primary"
                  onClick={gotoSignup}
                  disabled={isLoadingSession || isSessionCreated}>
                  <ShieldAlert /> Subscribe
                </Button>
              </React.Fragment>
            )}
            {currentUser?.hasActiveSubscription && (
              <Button
                variant="primary"
                onClick={() =>
                  navigate(
                    isDashboardIndex ? '/dashboard/reporting' : '/dashboard'
                  )
                }>
                View {isDashboardIndex ? 'Reporting' : 'Dashboard'}
              </Button>
            )}
          </div>
        </Col>
      </Row>
      {/*
        20230821 -  Cooper requested to only show the "reporting screen".
                    Commenting this in the chance they want it reverted.
      <Routes>
        <Route
          exact={true}
          path={'/'}
          element={
            <React.Fragment>
              <Row className="latest-scan-row">
                <Col md={{ span: 12 }}>
                  <div className={'cards-container'}>
                    {cards.map((n, i) => (
                      <DashboardCard
                        payload={n}
                        key={i}
                        delay={600 * (i + 1)}
                      />
                    ))}
                  </div>
                </Col>
              </Row>
            </React.Fragment>
          }></Route>
        <Route
          path="/reporting"
          element={<Reporting profileId={profileId} statsTypes={statsTypes} />}
        />
      </Routes> */}
      <Reporting profileId={profileId} statsTypes={statsTypes} />
    </React.Fragment>
  );
};

export default DashboardScreen;
