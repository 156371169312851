import Button from 'react-bootstrap/Button';
import { ErrorMessage } from '@hookform/error-message';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import React from 'react';
import { appConfig } from '../app/config';
import { useCreateCheckoutPromoSessionMutation } from '../redux/api/stripeApi';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import {
  ACCESS_TOKEN_KEY,
  useGetCurrentUserQuery,
  useLoginMutation,
  useRegisterMutation,
} from '../redux/api/authApi';
import { Alert, Col, Row } from 'react-bootstrap';
import {
  deleteLocalAnonymousProfileData,
  getLocalAnonymousProfileData,
  useCreateAnonymousProfileMutation,
} from '../redux/api/anonymousProfile';

const SignupScreen = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm();

  const [login] = useLoginMutation();

  const [createCheckoutPromoSession] = useCreateCheckoutPromoSessionMutation();

  const [createProfile] = useCreateAnonymousProfileMutation();

  const storageData = getLocalAnonymousProfileData();
  const [registerUser, { isLoading: isRegistering }] = useRegisterMutation();
  // const [login] = useLoginMutation();
  const navigate = useNavigate();

  useEffect(() => {
    // Get the current URL
    const url = new URL(window.location.href);

    // Get the query parameters
    const queryParams = new URLSearchParams(url.search);

    const couponCode = queryParams.get('coupon-code') || '';
    const frequency = ['yearly', 'monthly'].includes(
      queryParams.get('frequency')
    )
      ? queryParams.get('frequency')
      : 'monthly';

    localStorage.setItem('COUPON_CODE', couponCode);
    localStorage.setItem('FREQUENCY', frequency);
  }, []);

  const { data: currentUser, isLoading: isLoadingUser } =
    useGetCurrentUserQuery();

  useEffect(() => {
    if (!isLoadingUser) {
      if (currentUser?.email) {
        navigate('/dashboard');
        return;
      }

      // Ensure there is a local profile, if not take them to the start screen.
      const localProfile = getLocalAnonymousProfileData();
      if (!localProfile) {
        navigate('/free-trial-signup');
      }
    }
  }, [navigate, isLoadingUser, currentUser]);

  const submitForm = async (formData) => {
    let checkoutSession;

    const profileDataa = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      addresses: formData.addresses,
    };
    const profileDataRes = await createProfile(profileDataa);
    localStorage.setItem('id', profileDataRes.data.id);

    if (profileDataRes?.error) {
      const onRepErrors = profileDataRes?.error?.data?.errors;

      if (onRepErrors) {
        for (const [fieldName, message] of Object.entries(onRepErrors)) {
          setError(fieldName, { message });
        }
      } else if (profileDataRes?.error?.data?.message) {
        let errorMessages = '';
        if (Array.isArray(profileDataRes?.error?.data?.message)) {
          errorMessages = [
            ...new Set(profileDataRes?.error?.data?.message.flat()),
          ].join('\n');
        } else {
          errorMessages = profileDataRes?.error?.data?.message;
        }

        setError('firstName', {
          type: 'custom',
          message: errorMessages,
        });
      }
    }

    const localProfile = getLocalAnonymousProfileData();
    const postData = {
      email: formData.email,
      password: formData.password,
      firstName: localProfile.firstName,
      lastName: localProfile.lastName,
      anonymousProfileId: localProfile.id,
      sendMail: false,
    };

    const response = await registerUser(postData);

    if (response?.error) {
      const oneRepErrors = response?.error?.data?.errors;

      if (oneRepErrors) {
        for (const [fieldName, message] of Object.entries(oneRepErrors)) {
          setError(fieldName, { message });
        }
      } else if (response?.error?.data?.message) {
        let errorMessages = 'An error occurred.';
        if (Array.isArray(response?.error?.data?.message)) {
          errorMessages = [
            ...new Set(response?.error?.data?.message.flat()),
          ].join('\n');
        } else if (response?.error?.data?.message) {
          errorMessages = response?.error?.data?.message;
        }

        setError('email', {
          type: 'custom',
          message: errorMessages,
        });
      }
    } else {
      const loginData = {
        email: formData.email,
        password: formData.password,
      };

      await login(loginData); // to be uncommented

      if (localStorage.getItem(ACCESS_TOKEN_KEY)) {
        deleteLocalAnonymousProfileData();
        // if (isCoupon) {
        //   checkoutSession = await createCheckoutPromoSession();
        // } else {
        //   checkoutSession = await createCheckoutSession();
        // }

        const couponCode = localStorage.getItem('COUPON_CODE');
        const frequency = localStorage.getItem('FREQUENCY');

        let PromoSessionInput = {
          couponCode: couponCode,
          frequency: frequency,
        };

        checkoutSession = await createCheckoutPromoSession(PromoSessionInput);

        // checkoutSession = await createCheckoutSession();

        if (checkoutSession?.error) {
          const oneRepErrors = checkoutSession?.error?.data?.errors;

          if (oneRepErrors) {
            for (const [fieldName, message] of Object.entries(oneRepErrors)) {
              setError(fieldName, { message });
            }
          } else if (response?.error?.data?.message) {
            let errorMessages = 'An error occurred.';
            if (Array.isArray(response?.error?.data?.message)) {
              errorMessages = [
                ...new Set(response?.error?.data?.message.flat()),
              ].join('\n');
            } else if (response?.error?.data?.message) {
              errorMessages = response?.error?.data?.message;
            }

            setError('email', {
              type: 'custom',
              message: errorMessages,
            });
          }
        }

        window.location.assign(checkoutSession?.data?.stripeSessionUrl);
        localStorage.removeItem('COUPON_CODE');

        // navigate('/dashboard');
      }
    }
  };

  return (
    <Row>
      <Col>
        <Form onSubmit={handleSubmit(submitForm)} className="m-3">
          <Row>
            <h1>Create an account</h1>
            <div>
              <p>
                Start your free trial by filling out the below information. Next
                will be a payment page, after which you will complete your
                profile.
              </p>
            </div>
            <Form.Group>
              <ErrorMessage
                errors={errors}
                name={'firstName'}
                render={({ message }) => (
                  <Alert variant={'danger'}>{message}</Alert>
                )}
              />

              <FloatingLabel label="First Name" className="mb-3">
                <Form.Control
                  {...register('firstName', {
                    required: 'First Name is required',
                    value: storageData?.firstName,
                  })}
                  placeholder="First Name"
                />
              </FloatingLabel>
              <ErrorMessage
                errors={errors}
                name={'lastName'}
                render={({ message }) => (
                  <Alert variant={'danger'}>{message}</Alert>
                )}
              />

              <FloatingLabel label="Last Name" className="mb-3">
                <Form.Control
                  {...register('lastName', {
                    required: 'Last Name is required',
                    value: storageData?.lastName,
                  })}
                  placeholder="Last Name"
                />
              </FloatingLabel>
            </Form.Group>
            <Form.Group>
              <ErrorMessage
                errors={errors}
                name={'addresses[0].city'}
                render={({ message }) => (
                  <Alert variant={'danger'}>{message}</Alert>
                )}
              />

              <FloatingLabel label="City" className="mb-3">
                <Form.Control
                  {...register('addresses[0].city', {
                    required: 'City is required',
                    value: storageData?.addresses[0]?.city,
                  })}
                  placeholder="City"
                />
              </FloatingLabel>
            </Form.Group>

            <Form.Group>
              <ErrorMessage
                errors={errors}
                name={'addresses[0].state'}
                render={({ message }) => (
                  <Alert variant={'danger'}>{message}</Alert>
                )}
              />

              <FloatingLabel label="State" className="mb-3">
                <Form.Select
                  aria-label="State"
                  {...register('addresses[0].state', {
                    required: 'State is required',
                    value: storageData?.addresses[0]?.state,
                  })}
                  placeholder="State">
                  <option value="AL">Alabama</option>
                  <option value="AK">Alaska</option>
                  <option value="AZ">Arizona</option>
                  <option value="AR">Arkansas</option>
                  <option value="CA">California</option>
                  <option value="CO">Colorado</option>
                  <option value="CT">Connecticut</option>
                  <option value="DE">Delaware</option>
                  <option value="DC">District of Columbia</option>
                  <option value="FL">Florida</option>
                  <option value="GA">Georgia</option>
                  <option value="HI">Hawaii</option>
                  <option value="ID">Idaho</option>
                  <option value="IL">Illinois</option>
                  <option value="IN">Indiana</option>
                  <option value="IA">Iowa</option>
                  <option value="KS">Kansas</option>
                  <option value="KY">Kentucky</option>
                  <option value="LA">Louisiana</option>
                  <option value="ME">Maine</option>
                  <option value="MD">Maryland</option>
                  <option value="MA">Massachusetts</option>
                  <option value="MI">Michigan</option>
                  <option value="MN">Minnesota</option>
                  <option value="MS">Mississippi</option>
                  <option value="MO">Missouri</option>
                  <option value="MT">Montana</option>
                  <option value="NE">Nebraska</option>
                  <option value="NV">Nevada</option>
                  <option value="NH">New Hampshire</option>
                  <option value="NJ">New Jersey</option>
                  <option value="NM">New Mexico</option>
                  <option value="NY">New York</option>
                  <option value="NC">North Carolina</option>
                  <option value="ND">North Dakota</option>
                  <option value="OH">Ohio</option>
                  <option value="OK">Oklahoma</option>
                  <option value="OR">Oregon</option>
                  <option value="PA">Pennsylvania</option>
                  <option value="RI">Rhode Island</option>
                  <option value="SC">South Carolina</option>
                  <option value="SD">South Dakota</option>
                  <option value="TN">Tennessee</option>
                  <option value="TX">Texas</option>
                  <option value="UT">Utah</option>
                  <option value="VT">Vermont</option>
                  <option value="VA">Virginia</option>
                  <option value="WA">Washington</option>
                  <option value="WV">West Virginia</option>
                  <option value="WI">Wisconsin</option>
                  <option value="WY">Wyoming</option>
                </Form.Select>
              </FloatingLabel>
            </Form.Group>
            <Form.Group>
              <ErrorMessage
                errors={errors}
                name={'email'}
                render={({ message }) => (
                  <Alert variant={'danger'}>{message}</Alert>
                )}
              />

              <FloatingLabel label="Email address" className="mb-3">
                <Form.Control
                  {...register('email', { required: 'Email is required' })}
                  type="email"
                  placeholder="Email address"
                />
              </FloatingLabel>
            </Form.Group>
            <Form.Group>
              <ErrorMessage
                errors={errors}
                name={'password'}
                render={({ message }) => (
                  <Alert variant={'danger'}>{message}</Alert>
                )}
              />

              <FloatingLabel label="Password" className="mb-3">
                <Form.Control
                  {...register('password', {
                    required: 'Password is required',
                    minLength: {
                      value: 8,
                      message: 'Password must be at least 8 characters long',
                    },
                    maxLength: {
                      value: 100,
                      message:
                        'Password must be at less than 100 characters long',
                    },
                  })}
                  type="password"
                  placeholder="Password"
                />
              </FloatingLabel>
            </Form.Group>
            {/* <Form.Group>
              <ErrorMessage
                errors={errors}
                name={'agreed'}
                render={({ message }) => (
                  <Alert variant={'danger'}>{message}</Alert>
                )}
              />

              <Form.Check type={'checkbox'}>
                <Form.Check.Label>
                  <Form.Check.Input
                    // style={{ marginTop: '20px' }}
                    type={'checkbox'}
                    {...register('agreed', {
                      required:
                        'You need to agree to the Terms of Service ' +
                        'and the Privacy Policy.',
                    })}
                  />{' '}
                  I have read and I agree to the{' '}
                  <a
                    href={appConfig.urls.marketingWebSite + 'terms/'}
                    target={'_blank'}
                    rel="noreferrer">
                    Terms of Service
                  </a>{' '}
                  and{' '}
                  <a
                    href={appConfig.urls.marketingWebSite + 'privacy/'}
                    target={'_blank'}
                    rel="noreferrer">
                    Privacy Policy
                  </a>
                  .
                </Form.Check.Label>
              </Form.Check>
            </Form.Group> */}
            <Form.Group>
              <ErrorMessage
                errors={errors}
                name={'agreed'}
                render={({ message }) => (
                  <Alert variant={'danger'}>{message}</Alert>
                )}
              />
              <Form.Check className="vertical-align-checkbox">
                <Form.Check.Input
                  id="agreed-checkbox"
                  className="terms"
                  type={'checkbox'}
                  {...register('agreed', {
                    required:
                      'You need to agree to the Terms of Service ' +
                      'and the Privacy Policy.',
                  })}
                />
                <Form.Check.Label
                  htmlFor="agreed-checkbox"
                  className="mb-2 terms">
                  I have read and I agree to the{' '}
                  <a
                    href={appConfig.urls.marketingWebSite + 'terms/'}
                    target={'_blank'}
                    rel="noreferrer">
                    Terms of Service
                  </a>{' '}
                  and{' '}
                  <a
                    href={appConfig.urls.marketingWebSite + 'privacy/'}
                    target={'_blank'}
                    rel="noreferrer">
                    Privacy Policy
                  </a>
                  .
                </Form.Check.Label>
              </Form.Check>
            </Form.Group>
            <Form.Group>
              <div
                style={{
                  justifyContent: 'center',
                  display: 'flex',
                }}>
                <Button
                  variant="primary"
                  type="submit"
                  disabled={isRegistering}>
                  Start My Free Trial!
                </Button>
              </div>
            </Form.Group>
          </Row>
        </Form>
      </Col>
    </Row>
  );
};

export default SignupScreen;
