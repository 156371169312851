import React from 'react';
import toTitleCase from '../app/lib/toTitleCase';
import { useDocumentTitle } from '../hooks/useDocumentTitle';
import { useGetCurrentUserQuery } from '../redux/api/authApi';
import { useNavigate } from 'react-router-dom';
import { Button, Col, Row, Spinner } from 'react-bootstrap';
import {
  useCreateCheckoutSessionMutation,
  useCreatePortalSessionMutation,
} from '../redux/api/stripeApi';

const AccountScreen = () => {
  const { data: currentUser, isLoading: isLoadingUser } =
    useGetCurrentUserQuery();

  const navigate = useNavigate();

  useDocumentTitle('Account');

  const [createPortalSession, { isLoading: isLoadingPortalSession }] =
    useCreatePortalSessionMutation();

  const manageAccountSession = async () => {
    const sessionData = await createPortalSession();

    window.location.assign(sessionData.data.stripeSessionUrl);
  };

  const [
    createCheckoutSession,
    { isLoading: isLoadingSession, isSuccess: isSessionCreated },
  ] = useCreateCheckoutSessionMutation();

  const gotoSignup = async (e) => {
    e.preventDefault();
    const checkoutSession = await createCheckoutSession();

    window.location.assign(checkoutSession.data.stripeSessionUrl);
  };

  const manageAccountLink = () => {
    if (currentUser?.hasActiveSubscription) {
      return (
        <Button
          onClick={manageAccountSession}
          disabled={isLoadingPortalSession}>
          Manage Subscription
        </Button>
      );
    }

    return (
      <React.Fragment>
        <p className="inactive">No Active subscription</p>
        <Button
          onClick={gotoSignup}
          disabled={isLoadingSession || isSessionCreated}>
          Subscribe
        </Button>
      </React.Fragment>
    );
  };

  let subscriptionEndAt = '';
  if (currentUser?.subscriptionPeriodEndAt) {
    const date = new Date(currentUser.subscriptionPeriodEndAt);
    subscriptionEndAt = date.toLocaleDateString();
  }

  if (isLoadingUser) {
    return (
      <Spinner animation="border">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    );
  }

  return (
    <React.Fragment>
      <h2 className="title">Account</h2>
      <Row className="grid">
        <Row>
          <Col className="group">
            <h3>Details</h3>
            <p className="mt">
              Name: {currentUser.firstName} {currentUser.lastName}
            </p>
            <p>Email: {currentUser.email}</p>
            <Button
              onClick={() => {
                navigate('/account/edit');
              }}>
              Update Account Info
            </Button>
          </Col>
        </Row>
        <Row>
          <Col className="group">
            <h3>Subscription</h3>
            <p className="mt">
              <span className="label">Subscription Status:</span>{' '}
              {currentUser?.subscriptionStatus &&
                toTitleCase(currentUser.subscriptionStatus)}
            </p>
            <p>Next Billing Date: {subscriptionEndAt} </p>
            <React.Fragment>{manageAccountLink()}</React.Fragment>
          </Col>
        </Row>
        <Row>
          <Col className="group">
            <h3>Change Password</h3>
            <Button
              onClick={() => {
                navigate('/account/change-password');
              }}>
              {' '}
              Change Password
            </Button>
          </Col>
        </Row>
        <Row>
          <Col className="group">
            <h3>Profile</h3>
            <Button
              onClick={() => {
                navigate('/profile');
              }}>
              Manage Profile
            </Button>
          </Col>
        </Row>
      </Row>
    </React.Fragment>
  );
};

export default AccountScreen;
