import { ACCESS_TOKEN_KEY } from '../redux/api/authApi';
import { Alert } from 'react-bootstrap';
import { AppLogoWhite } from '../assets';
import { ErrorMessage } from '@hookform/error-message';
import { LinkContainer } from 'react-router-bootstrap';
import { appConfig } from '../app/config';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Container,
  Dropdown,
  FloatingLabel,
  Form,
  Modal,
  Nav,
  NavDropdown,
  Navbar,
} from 'react-bootstrap';
import React, { useState } from 'react';
import {
  deleteLocalAnonymousProfileData,
  getLocalAnonymousProfileData,
  useCreateAnonymousProfileMutation,
} from '../redux/api/anonymousProfile';
import {
  useCreateCheckoutPromoSessionMutation,
  useCreateCheckoutSessionMutation,
} from '../redux/api/stripeApi';
import {
  useGetCurrentUserQuery,
  useLogOutMutation,
  useRegisterMutation,
} from '../redux/api/authApi';

export default function Header() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm();
  const [showSignUpModal, setShowSignUpModal] = useState(false);
  const [isCoupon] = useState(false);

  const navigate = useNavigate();
  const [logout] = useLogOutMutation();
  // const [login] = useLoginMutation();

  const { data: currentUser, isSuccess } = useGetCurrentUserQuery();
  const [registerUser, { isLoading: isRegistering }] = useRegisterMutation();

  const doLogout = () => {
    logout();
    navigate('/login');
  };

  const [createCheckoutSession] = useCreateCheckoutSessionMutation();
  const [
    createCheckoutPromoSession,
    // { isLoading: isLoadingSession, isSuccess: isSessionCreated },
  ] = useCreateCheckoutPromoSessionMutation();

  const doLogoClick = () => {
    window.location.assign(appConfig.urls.marketingWebSite);
  };
  const handleSignUpClose = () => {
    setShowSignUpModal(false);
  };

  const [createProfile] = useCreateAnonymousProfileMutation();

  const storageData = getLocalAnonymousProfileData();
  // submit signup
  const submitForm = async (formData) => {
    let checkoutSession;

    const profileDataa = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      addresses: formData.addresses,
    };
    const profileDataRes = await createProfile(profileDataa);
    if (profileDataRes?.error) {
      const onRepErrors = profileDataRes?.error?.data?.errors;

      if (onRepErrors) {
        for (const [fieldName, message] of Object.entries(onRepErrors)) {
          setError(fieldName, { message });
        }
      } else if (profileDataRes?.error?.data?.message) {
        let errorMessages = '';
        if (Array.isArray(profileDataRes?.error?.data?.message)) {
          errorMessages = [
            ...new Set(profileDataRes?.error?.data?.message.flat()),
          ].join('\n');
        } else {
          errorMessages = profileDataRes?.error?.data?.message;
        }

        setError('firstName', {
          type: 'custom',
          message: errorMessages,
        });
      }
    }

    const localProfile = getLocalAnonymousProfileData();
    const postData = {
      email: formData.email,
      password: formData.password,
      firstName: localProfile.firstName,
      lastName: localProfile.lastName,
      anonymousProfileId: localProfile.id,
    };

    const response = await registerUser(postData);

    if (response?.error) {
      const oneRepErrors = response?.error?.data?.errors;

      if (oneRepErrors) {
        for (const [fieldName, message] of Object.entries(oneRepErrors)) {
          setError(fieldName, { message });
        }
      } else if (response?.error?.data?.message) {
        let errorMessages = 'An error occurred.';
        if (Array.isArray(response?.error?.data?.message)) {
          errorMessages = [
            ...new Set(response?.error?.data?.message.flat()),
          ].join('\n');
        } else if (response?.error?.data?.message) {
          errorMessages = response?.error?.data?.message;
        }

        setError('email', {
          type: 'custom',
          message: errorMessages,
        });
      }
    } else {
      // const loginData = {
      //   email: formData.email,
      //   password: formData.password,
      // };

      // const loginRes = await login(loginData); // to be uncommented

      if (localStorage.getItem(ACCESS_TOKEN_KEY)) {
        deleteLocalAnonymousProfileData();
        if (isCoupon) {
          checkoutSession = await createCheckoutPromoSession();
        } else {
          checkoutSession = await createCheckoutSession();
        }

        if (checkoutSession?.error) {
          const oneRepErrors = checkoutSession?.error?.data?.errors;

          if (oneRepErrors) {
            for (const [fieldName, message] of Object.entries(oneRepErrors)) {
              setError(fieldName, { message });
            }
          } else if (response?.error?.data?.message) {
            let errorMessages = 'An error occurred.';
            if (Array.isArray(response?.error?.data?.message)) {
              errorMessages = [
                ...new Set(response?.error?.data?.message.flat()),
              ].join('\n');
            } else if (response?.error?.data?.message) {
              errorMessages = response?.error?.data?.message;
            }

            setError('email', {
              type: 'custom',
              message: errorMessages,
            });
          }
        }

        setShowSignUpModal(false);
        window.location.assign(checkoutSession?.data?.stripeSessionUrl);

        // navigate('/dashboard');
      }
    }
  };

  return (
    <React.Fragment>
      <Navbar collapseOnSelect expand="lg">
        <Container fluid>
          <Navbar.Brand onClick={doLogoClick}>
            <img src={AppLogoWhite} alt="logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            {!currentUser?.email && (
              <React.Fragment>
                <Nav.Link
                  href={appConfig.urls.howItWorks}
                  target="_blank"
                  className="how-it-works">
                  How it works
                </Nav.Link>
                <LinkContainer to="/login">
                  <Nav.Link className="btn">Login</Nav.Link>
                </LinkContainer>

                {/* mycode */}
                {/* <LinkContainer to="/login">
                  <Nav.Link
                    className="btn"
                    onClick={buyItNow}
                    disabled={isLoadingSession || isSessionCreated}>
                    Buy it Now
                  </Nav.Link>
                </LinkContainer> */}
                {/* <LinkContainer to="/login">
                  <Nav.Link
                    className="btn"
                    onClick={buyItNowPromo}
                    disabled={isLoadingSession || isSessionCreated}>
                    10% discount Coupon
                  </Nav.Link>
                </LinkContainer> */}
                {/* mycode */}
              </React.Fragment>
            )}
            {isSuccess && currentUser?.email && (
              <React.Fragment>
                {currentUser?.hasActiveSubscription ? (
                  <LinkContainer to="/dashboard">
                    <Nav.Link>Dashboard</Nav.Link>
                  </LinkContainer>
                ) : (
                  <React.Fragment>
                    <LinkContainer to="/dashboard">
                      <Nav.Link>Dashboard</Nav.Link>
                    </LinkContainer>
                  </React.Fragment>
                )}
                <NavDropdown title={currentUser?.firstName}>
                  <Dropdown.Item
                    onClick={() => {
                      navigate('/account');
                    }}>
                    Account
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      navigate('/profile');
                    }}>
                    Manage Profile
                  </Dropdown.Item>
                  <NavDropdown.Divider />
                  <Dropdown.Item onClick={doLogout}>Logout</Dropdown.Item>
                </NavDropdown>
              </React.Fragment>
            )}
          </Navbar.Collapse>
        </Container>
      </Navbar>

      {/* modal for signup */}

      <Modal
        autoFocus
        centered
        show={showSignUpModal}
        onHide={handleSignUpClose}>
        <Modal.Header closeButton>
          <Modal.Title>Sign Up</Modal.Title>
          <Modal.Dialog>Please signup before subscribing</Modal.Dialog>
        </Modal.Header>
        <Form onSubmit={handleSubmit(submitForm)} className="m-3">
          <Modal.Body>
            <Form.Group>
              <ErrorMessage
                errors={errors}
                name={'firstName'}
                render={({ message }) => (
                  <Alert variant={'danger'}>{message}</Alert>
                )}
              />

              <FloatingLabel label="First Name" className="mb-3">
                <Form.Control
                  {...register('firstName', {
                    required: 'First Name is required',
                    value: storageData?.firstName,
                  })}
                  placeholder="First Name"
                />
              </FloatingLabel>
              <ErrorMessage
                errors={errors}
                name={'lastName'}
                render={({ message }) => (
                  <Alert variant={'danger'}>{message}</Alert>
                )}
              />

              <FloatingLabel label="Last Name" className="mb-3">
                <Form.Control
                  {...register('lastName', {
                    required: 'Last Name is required',
                    value: storageData?.lastName,
                  })}
                  placeholder="Last Name"
                />
              </FloatingLabel>
            </Form.Group>
            <Form.Group>
              <ErrorMessage
                errors={errors}
                name={'addresses[0].city'}
                render={({ message }) => (
                  <Alert variant={'danger'}>{message}</Alert>
                )}
              />

              <FloatingLabel label="City" className="mb-3">
                <Form.Control
                  {...register('addresses[0].city', {
                    required: 'City is required',
                    value: storageData?.addresses[0]?.city,
                  })}
                  placeholder="City"
                />
              </FloatingLabel>
            </Form.Group>

            <Form.Group>
              <ErrorMessage
                errors={errors}
                name={'addresses[0].state'}
                render={({ message }) => (
                  <Alert variant={'danger'}>{message}</Alert>
                )}
              />

              <FloatingLabel label="State" className="mb-3">
                <Form.Select
                  aria-label="State"
                  {...register('addresses[0].state', {
                    required: 'State is required',
                    value: storageData?.addresses[0]?.state,
                  })}
                  placeholder="State">
                  <option value="AL">Alabama</option>
                  <option value="AK">Alaska</option>
                  <option value="AZ">Arizona</option>
                  <option value="AR">Arkansas</option>
                  <option value="CA">California</option>
                  <option value="CO">Colorado</option>
                  <option value="CT">Connecticut</option>
                  <option value="DE">Delaware</option>
                  <option value="DC">District of Columbia</option>
                  <option value="FL">Florida</option>
                  <option value="GA">Georgia</option>
                  <option value="HI">Hawaii</option>
                  <option value="ID">Idaho</option>
                  <option value="IL">Illinois</option>
                  <option value="IN">Indiana</option>
                  <option value="IA">Iowa</option>
                  <option value="KS">Kansas</option>
                  <option value="KY">Kentucky</option>
                  <option value="LA">Louisiana</option>
                  <option value="ME">Maine</option>
                  <option value="MD">Maryland</option>
                  <option value="MA">Massachusetts</option>
                  <option value="MI">Michigan</option>
                  <option value="MN">Minnesota</option>
                  <option value="MS">Mississippi</option>
                  <option value="MO">Missouri</option>
                  <option value="MT">Montana</option>
                  <option value="NE">Nebraska</option>
                  <option value="NV">Nevada</option>
                  <option value="NH">New Hampshire</option>
                  <option value="NJ">New Jersey</option>
                  <option value="NM">New Mexico</option>
                  <option value="NY">New York</option>
                  <option value="NC">North Carolina</option>
                  <option value="ND">North Dakota</option>
                  <option value="OH">Ohio</option>
                  <option value="OK">Oklahoma</option>
                  <option value="OR">Oregon</option>
                  <option value="PA">Pennsylvania</option>
                  <option value="RI">Rhode Island</option>
                  <option value="SC">South Carolina</option>
                  <option value="SD">South Dakota</option>
                  <option value="TN">Tennessee</option>
                  <option value="TX">Texas</option>
                  <option value="UT">Utah</option>
                  <option value="VT">Vermont</option>
                  <option value="VA">Virginia</option>
                  <option value="WA">Washington</option>
                  <option value="WV">West Virginia</option>
                  <option value="WI">Wisconsin</option>
                  <option value="WY">Wyoming</option>
                </Form.Select>
              </FloatingLabel>
            </Form.Group>
            <Form.Group>
              <ErrorMessage
                errors={errors}
                name={'email'}
                render={({ message }) => (
                  <Alert variant={'danger'}>{message}</Alert>
                )}
              />

              <FloatingLabel label="Email address" className="mb-3">
                <Form.Control
                  {...register('email', { required: 'Email is required' })}
                  type="email"
                  placeholder="Email address"
                />
              </FloatingLabel>
            </Form.Group>
            <Form.Group>
              <ErrorMessage
                errors={errors}
                name={'password'}
                render={({ message }) => (
                  <Alert variant={'danger'}>{message}</Alert>
                )}
              />

              <FloatingLabel label="Password" className="mb-3">
                <Form.Control
                  {...register('password', {
                    required: 'Password is required',
                    minLength: {
                      value: 8,
                      message: 'Password must be at least 8 characters long',
                    },
                    maxLength: {
                      value: 100,
                      message:
                        'Password must be at less than 100 characters long',
                    },
                  })}
                  type="password"
                  placeholder="Password"
                />
              </FloatingLabel>
            </Form.Group>
            <Form.Group>
              <ErrorMessage
                errors={errors}
                name={'agreed'}
                render={({ message }) => (
                  <Alert variant={'danger'}>{message}</Alert>
                )}
              />

              <Form.Check type={'checkbox'}>
                <Form.Check.Label>
                  <Form.Check.Input
                    type={'checkbox'}
                    {...register('agreed', {
                      required:
                        'You need to agree to the Terms of Service ' +
                        'and the Privacy Policy.',
                    })}
                  />
                  I have read and I agree to the{' '}
                  <a
                    href={appConfig.urls.marketingWebSite + 'terms/'}
                    target={'_blank'}
                    rel="noreferrer">
                    Terms of Service
                  </a>{' '}
                  and{' '}
                  <a
                    href={appConfig.urls.marketingWebSite + 'privacy/'}
                    target={'_blank'}
                    rel="noreferrer">
                    Privacy Policy
                  </a>
                  .
                </Form.Check.Label>
              </Form.Check>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" type="submit" disabled={isRegistering}>
              Sign Up
            </Button>
            <Button variant="secondary" onClick={handleSignUpClose}>
              Close
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </React.Fragment>
  );
}
