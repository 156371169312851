import Alert from 'react-bootstrap/Alert';
import DashboardCard from '../components/DashboardCard';
import { ErrorMessage } from '@hookform/error-message';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import Map from '../screens/dashboard/Map';
import StartForm from '../components/StartForm';
import { appConfig } from '../app/config';
import { getAddresses } from '../app/lib/getAddresses';
import { getCards } from '../app/lib/getCards';
import { getEmails } from '../app/lib/getEmails';
import { getPhones } from '../app/lib/getPhones';
import { getRelatives } from '../app/lib/getRelatives';
import toTitleCase from '../app/lib/toTitleCase';
import { useDocumentTitle } from '../hooks/useDocumentTitle';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useInterval } from '../hooks/useInterval';
import { useNavigate } from 'react-router-dom';
import {
  ACCESS_TOKEN_KEY,
  useGetCurrentUserQuery,
  useLoginMutation,
  useRegisterMutation,
} from '../redux/api/authApi';
import { Button, Col, Modal, Row, Spinner } from 'react-bootstrap';
import React, { useState } from 'react';
import {
  deleteLocalAnonymousProfileData,
  getLocalAnonymousProfileData,
  useGetAnonymousProfileCurrentStatsQuery,
  useGetAnonymousScanResultsQuery,
  useGetAnonymousScansQuery,
} from '../redux/api/anonymousProfile';

const FreeScanScreen = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm();

  const [showSignUpModal, setShowSignUpModal] = useState(false);

  const profileData = getLocalAnonymousProfileData();
  const navigate = useNavigate();
  const [login] = useLoginMutation();
  const {
    data: profileScans,
    isLoading: isLoadingScans,
    refetch: refetchScans,
  } = useGetAnonymousScansQuery(profileData?.id);
  // console.log('profileScan:', profileScans);

  const profileScanFinished = profileScans?.[0]?.status === 'finished';

  const { data: currentUser, isLoading: isLoadingUser } =
    useGetCurrentUserQuery();

  const scanStatusForEffect = profileScans?.[0]?.status;

  const {
    data: currentStats,
    isLoading: isLoadingStats,
    refetch: refetchStats,
  } = useGetAnonymousProfileCurrentStatsQuery(profileData?.id);

  // console.log('currentStats:', currentStats);

  const {
    data: scanResults = [],
    refetch: refetchScanResults,
    isSuccess: scanResultsSuccess,
  } = useGetAnonymousScanResultsQuery(profileData?.id);

  const [registerUser, { isLoading: isRegistering }] = useRegisterMutation();

  useDocumentTitle('Free Scan');

  useEffect(() => {
    if (!isLoadingUser) {
      if (currentUser?.email) {
        navigate('/dashboard');
        return;
      }

      // Ensure there is a local profile, if not take them to the start screen.
      const localProfile = getLocalAnonymousProfileData();
      if (!localProfile) {
        navigate('/free-trial-signup');
      }
    }
  }, [navigate, isLoadingUser, currentUser]);

  useInterval(
    () => {
      refetchScans();
    },
    profileScanFinished ? null : 100
  );

  useInterval(
    () => {
      refetchStats();
    },
    profileScanFinished ? null : 100
  );

  useEffect(() => {
    refetchScanResults();
  }, [refetchScanResults, scanStatusForEffect, currentStats?.total]);

  if (isLoadingScans || isLoadingStats) {
    return (
      <Spinner animation="border">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    );
  }

  const addresses = getAddresses(scanResults);
  // console.log('addresses:', addresses);
  const relatives = getRelatives(scanResults);
  const emails = getEmails(scanResults);
  const phones = getPhones(scanResults);
  const cards = getCards(addresses, relatives, phones, emails, true, () =>
    setShowSignUpModal(true)
  );

  const submitForm = async (formData) => {
    const localProfile = getLocalAnonymousProfileData();

    const postData = {
      email: formData.email,
      password: formData.password,
      firstName: localProfile.firstName,
      lastName: localProfile.lastName,
      anonymousProfileId: localProfile.id,
    };

    const response = await registerUser(postData);
    if (response?.error) {
      const oneRepErrors = response?.error?.data?.errors;

      if (oneRepErrors) {
        for (const [fieldName, message] of Object.entries(oneRepErrors)) {
          setError(fieldName, { message });
        }
      } else if (response?.error?.data?.message) {
        let errorMessages = 'An error occurred.';
        if (Array.isArray(response?.error?.data?.message)) {
          errorMessages = [
            ...new Set(response?.error?.data?.message.flat()),
          ].join('\n');
        } else if (response?.error?.data?.message) {
          errorMessages = response?.error?.data?.message;
        }

        setError('email', {
          type: 'custom',
          message: errorMessages,
        });
      }
    } else {
      const loginData = {
        email: formData.email,
        password: formData.password,
      };

      await login(loginData);
      if (localStorage.getItem(ACCESS_TOKEN_KEY)) {
        deleteLocalAnonymousProfileData();
        navigate('/dashboard');
      }
    }
  };

  const handleSignUpClose = () => {
    setShowSignUpModal(false);
  };

  const showDashboardCards = profileScanFinished && scanResultsSuccess;

  return (
    <React.Fragment>
      <Map addresses={addresses} />
      <Row>
        <Col md={{ span: 4 }}>
          {profileScanFinished && (
            <React.Fragment>
              {currentStats.total === 0 && (
                <div className={'dashboard-intro-container'}>
                  <h1>
                    No records were found for <br />
                    <strong>
                      {toTitleCase(profileData.firstName)}{' '}
                      {toTitleCase(profileData.lastName)}{' '}
                    </strong>
                    <br />
                    <strong>
                      {toTitleCase(profileData.addresses[0].city)},{' '}
                      {profileData.addresses[0].state}
                    </strong>
                  </h1>
                  <ul>
                    <li className={'sub-heading'}>
                      If you have used a different name or have had a maiden
                      name, try those.
                    </li>
                    <li className={'sub-heading'}>
                      Try searching your name with a previous city
                    </li>
                    <li className={'sub-heading'}>
                      Make sure you are using the full version of your name
                      (e.g. Alexander instead of Alex)
                    </li>
                  </ul>
                </div>
              )}
              {currentStats.total > 0 && (
                <div className={'dashboard-intro-container'}>
                  <h1>
                    <strong>{currentStats.total}</strong> records found for{' '}
                    <strong>
                      {toTitleCase(profileData.firstName)}{' '}
                      {toTitleCase(profileData.lastName)}{' '}
                    </strong>
                    -{' '}
                    <strong>
                      {toTitleCase(profileData.addresses[0].city)},{' '}
                      {profileData.addresses[0].state}
                    </strong>
                  </h1>
                  <p className={'sub-heading'}>
                    Sign up today to get monthly monitoring and reports!
                  </p>
                  <Button
                    variant="primary"
                    onClick={() => setShowSignUpModal(true)}>
                    See Your Results
                  </Button>
                </div>
              )}
            </React.Fragment>
          )}
          {!profileScanFinished && (
            <React.Fragment>
              <div className={'dashboard-intro-container'}>
                <h1>
                  Free scan in <strong>progress</strong>...
                </h1>
                <Spinner animation="grow">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
                <p className={'sub-heading'}>
                  <strong>{currentStats.total}</strong> records found
                </p>
                <p>
                  We are scanning for your private information. This can take
                  several minutes.
                </p>
              </div>
            </React.Fragment>
          )}
        </Col>

        {/* free scan card information */}
        <Col md={{ span: 8 }}>
          {showDashboardCards && currentStats.total === 0 && (
            <div className={'start-form'}>
              <StartForm duringFreeScan />
            </div>
          )}
        </Col>

        {showDashboardCards && currentStats.total > 0 && (
          <Col md={{ span: 10 }}>
            <div className={'cards-container'}>
              {cards.map((n, i) => (
                <DashboardCard payload={n} key={i} delay={600 * (i + 1)} />
              ))}
            </div>
          </Col>
        )}
      </Row>
      <Modal
        autoFocus
        centered
        show={showSignUpModal}
        onHide={handleSignUpClose}>
        <Modal.Header closeButton>
          <Modal.Title>Sign Up</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit(submitForm)} className="m-3">
          <Modal.Body>
            <Form.Group>
              <ErrorMessage
                errors={errors}
                name={'email'}
                render={({ message }) => (
                  <Alert variant={'danger'}>{message}</Alert>
                )}
              />

              <FloatingLabel label="Email address" className="mb-3">
                <Form.Control
                  {...register('email', { required: 'Email is required' })}
                  type="email"
                  placeholder="Email address"
                />
              </FloatingLabel>
            </Form.Group>
            <Form.Group>
              <ErrorMessage
                errors={errors}
                name={'password'}
                render={({ message }) => (
                  <Alert variant={'danger'}>{message}</Alert>
                )}
              />

              <FloatingLabel label="Password" className="mb-3">
                <Form.Control
                  {...register('password', {
                    required: 'Password is required',
                    minLength: {
                      value: 8,
                      message: 'Password must be at least 8 characters long',
                    },
                    maxLength: {
                      value: 100,
                      message:
                        'Password must be at less than 100 characters long',
                    },
                  })}
                  type="password"
                  placeholder="Password"
                />
              </FloatingLabel>
            </Form.Group>
            <Form.Group>
              <ErrorMessage
                errors={errors}
                name={'agreed'}
                render={({ message }) => (
                  <Alert variant={'danger'}>{message}</Alert>
                )}
              />

              <Form.Check type={'checkbox'}>
                <Form.Check.Label>
                  <Form.Check.Input
                    type={'checkbox'}
                    {...register('agreed', {
                      required:
                        'You need to agree to the Terms of Service ' +
                        'and the Privacy Policy.',
                    })}
                  />
                  I have read and I agree to the{' '}
                  <a
                    href={appConfig.urls.marketingWebSite + 'terms/'}
                    target={'_blank'}
                    rel="noreferrer">
                    Terms of Service
                  </a>{' '}
                  and{' '}
                  <a
                    href={appConfig.urls.marketingWebSite + 'privacy/'}
                    target={'_blank'}
                    rel="noreferrer">
                    Privacy Policy
                  </a>
                  .
                </Form.Check.Label>
              </Form.Check>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" type="submit" disabled={isRegistering}>
              Sign Up
            </Button>
            <Button variant="secondary" onClick={handleSignUpClose}>
              Close
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default FreeScanScreen;
