import { Alert } from 'react-bootstrap';
import React from 'react';

// profileSections defines the sections of the profile
// the UI is auto generated from the profileSections object
// to add a new field to the ProfileScreen, add it to this object

export const profileSections = [
  {
    title: 'Birth Date',
    field: 'birthDate',
    name: 'Birth Date',
    inputFields: [
      { name: 'Birth Date', field: 'birthDate', required: true, type: 'date' },
    ],
    formatter: (value) => {
      if (!value) {
        return <Alert variant={'warning'}>Birth Date is required</Alert>;
      }
      return value;
    },
  },
  {
    title: 'First Name Variations',
    desc:
      'Include any alternative versions of your first name ' +
      'you have used in the past, such as nicknames, short ' +
      'forms, or variations resulting from a name change, etc.',
    field: 'firstNames',
    name: 'First Name',
    inputFields: [{ name: 'First Name', field: 'firstName', required: true }],
    formatter: (item) => {
      return item?.firstName;
    },
  },
  {
    title: 'Middle Names',
    desc:
      'Include any middle names you have used, include ' +
      'variations of the names too',
    field: 'middleNames',
    name: 'Middle Name',
    inputFields: [{ name: 'Middle Name', field: 'middleName', required: true }],
    formatter: (item) => {
      return item?.middleName;
    },
  },
  {
    title: 'Last Name Variations',
    desc:
      'Include any of the last names you have used in the past, ' +
      'such as maiden names, name changes, or variations resulting ' +
      'from a marriage, divorce or etc.',
    field: 'lastNames',
    name: 'Last Name',
    inputFields: [{ name: 'Last Name', field: 'lastName', required: true }],
    formatter: (item) => {
      return item?.lastName;
    },
  },
  {
    title: 'Emails',
    field: 'emails',
    name: 'Email',
    inputFields: [{ name: 'Email', field: 'email', required: true }],
    formatter: (item) => {
      return item?.email;
    },
  },
  {
    title: 'Phone Numbers',
    field: 'phoneNumbers',
    name: 'Phone Number',
    inputFields: [
      {
        name: 'Phone Number',
        field: 'number',
        required: true,
        validate: (value) => {
          const regex = /^[0-9]+$/;
          if (value.length !== 10 || !regex.test(value)) {
            return 'Must be a 10 digit phone number';
          }

          return true;
        },
      },
    ],
    formatter: (item) => {
      return item?.number;
    },
  },
  {
    title: 'Addresses',
    field: 'addresses',
    name: 'Address',
    inputFields: [
      { name: 'Address Line', field: 'addressLine' },
      { name: 'City', field: 'city', required: true },
      { name: 'State', field: 'state', required: true },
      { name: 'Zip', field: 'zip' },
    ],
    formatter: (address, toText = false) => {
      if (toText)
        return `${address?.addressLine || ''}
            ${address?.city}, ${address?.state} ${address?.zip || ''}`;

      return (
        <div>
          {address?.addressLine && (
            <React.Fragment>
              {address?.addressLine}
              <br />
            </React.Fragment>
          )}
          {address?.city}, {address?.state} {address?.zip}
        </div>
      );
    },
  },
];
