import TypeItem from '../screens/monitoring/TypeItem';
import { useGetScanResultsSummaryQuery } from '../redux/api/profileApi';
import { useInterval } from '../hooks/useInterval';
import { Col, Row, Spinner } from 'react-bootstrap';
import React, { useState } from 'react';

const ScanResultsSummary = ({ scanResults, profileId }) => {
  const {
    data: scanSummary,
    isLoading: isLoadingData,
    refetch: refetchScanSummary,
  } = useGetScanResultsSummaryQuery(profileId);

  const [activeFilter, setActiveFilter] = useState('all');
  // console.log('scan summary:', scanSummary);

  // useInterval(() => {
  //   refetchScanSummary();
  // }, 1000);
  useInterval(
    () => {
      refetchScanSummary();
    },
    scanResults?.length > 1
      ? scanSummary?.addresses?.length > 1
        ? null
        : 100
      : null
    // scanSummary?.addresses.length > 1 ? null : 1000
  );

  if (isLoadingData) {
    return (
      <Col md={{ span: 12 }} className={'section-header'}>
        <div className={'section-header'}>Processing Information</div>
        <Spinner animation="grow">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </Col>
    );
  }

  const foundSections = [
    { key: 'all', text: 'All' },
    { key: 'names', text: 'Names' },
    { key: 'addresses', text: 'Addresses' },
    { key: 'phones', text: 'Phones' },
    { key: 'emails', text: 'Emails' },
    { key: 'relatives', text: 'Relatives' },
    { key: 'ages', text: 'Ages' },
  ];

  // console.log('scanSummary:', scanSummary);
  // console.log('activeFilter:', activeFilter);

  return (
    <Row className="scan-summary-row container-fluid py-2">
      <Col md={{ span: 12 }} className={'section-header'}>
        {foundSections.map((n, i) => (
          <div
            className={`section-header-item ${
              activeFilter === n.key ? 'is-active' : ''
            }`}
            tabIndex={1}
            key={i}
            onClick={() => setActiveFilter(n.key)}
            onKeyUp={(e) => {
              if (e.key === 'Enter') {
                setActiveFilter(n.key);
              }
            }}
            aria-label={'Filter by ' + n.text}>
            {n.text}
          </div>
        ))}
      </Col>
      <Col className={'section-types'}>
        {foundSections
          .filter((n) => n.key === activeFilter)
          .map((foundSection, _i) => {
            return (
              <div
                className={`section-types-category ${foundSection.key}`}
                key={`foundSection-${foundSection.key}`}>
                <h2>{foundSection.text}</h2>
                <Row>
                  {scanSummary?.[foundSection.key]?.map((foundItem, i) => (
                    <TypeItem
                      key={i}
                      item={foundItem}
                      profileId={profileId}
                      section={foundSection}
                    />
                  ))}
                </Row>
              </div>
            );
          })}
      </Col>
    </Row>
  );
};

export default ScanResultsSummary;
