import App from './App';
import { Provider } from 'react-redux';
import React from 'react';
import ReactGA from 'react-ga4';
import { appConfig } from './app/config';
import { createRoot } from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { store } from './redux/store';

import './styles/main.scss';

const container = document.getElementById('root');
const root = createRoot(container);

if (appConfig.ga4MeasurementId) {
  ReactGA.initialize([
    {
      trackingId: appConfig.ga4MeasurementId,
      gaOptions: {
        testMode: appConfig.env !== 'production',
      }, // optional
      // gtagOptions: {...}, // optional
    },
  ]);
}

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// eslint-disable-next-line no-console
reportWebVitals(console.log);
