// import DashboardCard from '../components/DashboardCard';
import { Link } from 'react-router-dom';
import Map from '../screens/dashboard/Map';
import React from 'react';
import Reporting from './dashboard/Reporting';
// import StartForm from '../components/StartForm';
import { getAddresses } from '../app/lib/getAddresses';
//cards
// import { getCards } from '../app/lib/getCards';
// import { getEmails } from '../app/lib/getEmails';
// import { getPhones } from '../app/lib/getPhones';
// import { getRelatives } from '../app/lib/getRelatives';
//cards
import toTitleCase from '../app/lib/toTitleCase';
import { useDocumentTitle } from '../hooks/useDocumentTitle';
import { useEffect } from 'react';
import { useGetAnonymousScanResultsQuery } from '../redux/api/anonymousProfile';
import { useGetCurrentUserQuery } from '../redux/api/authApi';
import { useGetProfileQuery } from '../redux/api/profileApi';
import { useInterval } from '../hooks/useInterval';
// import { useNavigate } from 'react-router-dom';
import { Col, Row, Spinner } from 'react-bootstrap';
import {
  useGetProfileCurrentStatsQuery,
  useGetScansQuery,
} from '../redux/api/profileApi';

const FilteringRecordsScreen = () => {
  // const navigate = useNavigate();

  const { data: currentUser, isLoading: isLoadingUser } =
    useGetCurrentUserQuery();

  const profileId = currentUser?.externalProfileIds?.[0];

  const {
    data: profileScans,
    isLoading: isLoadingScans,
    refetch: refetchScans,
  } = useGetScansQuery(profileId);

  // console.log('profilescans:', profileScans);

  const {
    data: scanResults = [],
    refetch: refetchScanResults,
    // isSuccess: scanResultsSuccess,
  } = useGetAnonymousScanResultsQuery(profileId);
  // console.log('scanResults:', scanResults);

  const scanStatusForEffect = profileScans?.[0]?.status;

  const { data: profileData, isLoading: isLoadingProfileData } =
    useGetProfileQuery(profileId);
  // console.log('profileData:', profileData);

  const profileScanFinished = profileScans?.[0]?.status === 'finished';

  const {
    data: currentStats,
    isLoading: isLoadingStats,
    refetch: refetchStats,
  } = useGetProfileCurrentStatsQuery(profileId);
  // console.log('currentStats:', currentStats);

  useDocumentTitle('Filtering Results');

  useInterval(
    () => {
      refetchScans();
    },
    profileScans?.[0]?.status === 'finished' ? null : 100
  );

  // useInterval(
  //   () => {
  //     refetchStats();
  //   },
  //   profileScans?.[0]?.status === 'finished' ? null : 100
  // );

  // useInterval(
  //   () => {
  //     refetchScans();
  //   },
  //   profileScanFinished ? null : 100
  // );

  useInterval(
    () => {
      refetchStats();
    },
    profileScanFinished ? null : 100
  );

  useEffect(() => {
    refetchScanResults();
  }, [refetchScanResults, scanStatusForEffect, currentStats?.total]);

  if (!profileId) {
    return (
      <div>
        <h1 className="sub-heading">Please Subscribe to scan.</h1>
      </div>
    );
  }

  if (
    isLoadingProfileData ||
    isLoadingUser ||
    isLoadingScans ||
    isLoadingStats
  ) {
    return (
      <Spinner animation="border">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    );
  }

  const addresses = getAddresses(scanResults);
  // console.log('scan results:', scanResults);
  //-cards
  // const relatives = getRelatives(scanResults);
  // const emails = getEmails(scanResults);
  // const phones = getPhones(scanResults);
  // const cards = getCards(addresses, relatives, phones, emails, true);
  // const showDashboardCards = profileScanFinished && scanResultsSuccess;
  //-cards

  const statsTypes = [
    {
      key: 'new',
      text: 'New',
      tooltip: 'The number of newly found records that have yet to be removed',
    },
    {
      key: 'totalInProgress',
      text: 'In Progress',
      tooltip: 'The number of records that are in progress being removed',
    },
    {
      key: 'removed',
      text: 'Removed',
      tooltip: 'The number of records that have been removed',
    },
    {
      key: 'total',
      text: 'Total',
      tooltip: 'The total number of records found and removed',
    },
  ];

  return (
    <React.Fragment>
      <Map addresses={addresses} />
      <Row>
        {/* <Col md={{ span: 8, offset: 2 }}> */}
        <Col md={{ span: 8 }}>
          {profileScans?.[0]?.status === 'finished' && (
            <React.Fragment>
              <div className={'dashboard-intro-container'}>
                <h1>
                  <strong>{currentStats?.total}</strong> records found for{' '}
                  <span className={'d-block'}>
                    {`${toTitleCase(profileData?.firstName)} ${toTitleCase(
                      profileData?.lastName
                    )}, ${profileData?.addresses[0].city} ${
                      profileData?.addresses[0].state
                    }`}
                  </span>
                </h1>
              </div>
            </React.Fragment>
          )}
          {profileScans?.[0]?.status !== 'finished' && (
            <React.Fragment>
              <h1>Filtering your results...</h1>
              <p>
                We are doing a new scan with the additional profile information.
                This can take several minutes.
              </p>
              <Spinner animation="grow">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
              <p>
                <strong>{currentStats?.total}</strong> records found
              </p>
            </React.Fragment>
          )}
          {profileScans?.[0]?.status === 'finished' && (
            <React.Fragment>
              <div className={'sign-up-cta'}>
                <h2 className="sub-heading">
                  Congratulation, your Removals have begun!
                </h2>
                <p className="sub-heading">
                  Below are your results. At any time you may click{' '}
                  <Link className="dashboard-link" to="/dashboard">
                    Dashboard
                  </Link>
                  <br /> on the top right to see progress and details.
                </p>
                <p className="sub-heading">
                  We’ll alert you upon the first removal as well as send a
                  monthly <br /> update. You’re all set and don’t need to do
                  anything else!
                </p>
              </div>
              {/* <Button variant="primary" 
              onClick={() => navigate('/dashboard')}>
                Dashboard
              </Button> */}
            </React.Fragment>
          )}
        </Col>

        {/* card screens */}
        {/* <Col md={{ span: 8 }}>
          {showDashboardCards && currentStats.total === 0 && (
            <div className={'start-form'}>
              <StartForm duringFreeScan />
            </div>
          )}
        </Col>

        {showDashboardCards && currentStats.total > 0 && (
          <Col md={{ span: 10 }}>
            <div className={'cards-container'}>
              {cards.map((n, i) => (
                <DashboardCard payload={n} key={i} delay={600 * (i + 1)} />
              ))}
            </div>
          </Col>
        )} */}
      </Row>
      <Reporting
        profileId={profileId}
        statsTypes={statsTypes}
        scanResults={scanResults}
      />
    </React.Fragment>
  );
};

export default FilteringRecordsScreen;
