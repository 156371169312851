import StartForm from '../components/StartForm';
import { Col, Row } from 'react-bootstrap';
import React, { useEffect } from 'react';

const StartScreen = () => {
  useEffect(() => {
    // Get the current URL
    const url = new URL(window.location.href);

    // Get the query parameters
    const queryParams = new URLSearchParams(url.search);

    // Retrieve specific query parameters
    const couponCode = queryParams.get('coupon-code');
    localStorage.setItem('COUPON_CODE', couponCode);
  }, []);
  return (
    <React.Fragment>
      <Row>
        <Col>
          <h1>Remove your information from 190+ sites</h1>
          <h3>Let&apos;s get started!</h3>
          <p>Enter your name, city and state, and we will run a free scan.</p>
          <StartForm />
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default StartScreen;
