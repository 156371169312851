export const getRelatives = (scanResults = []) => {
  return scanResults
    .filter((n) => n.status !== 'removed')
    .flatMap((n) => n.relatives)
    .reduce((acc, curr) => {
      const personName = acc.find((n) => n.value === curr);
      if (!personName) {
        acc.push({ type: 'relative', value: curr, count: 1 });
      } else {
        personName.count += 1;
      }
      return acc;
    }, []);
};
