import AppHeader from '../components/AppHeader';
import Container from 'react-bootstrap/Container';
import Footer from '../components/Footer';
import { Outlet } from 'react-router-dom';
import React from 'react';
import { useLocation } from 'react-router-dom';

const Layout = () => {
  const location = useLocation();
  const locationParts = location.pathname.split('/');

  const containerClasses = ['route-container'];

  if (locationParts[1]) {
    containerClasses.push(`route-container-${locationParts[1]}`);
  }

  return (
    <div className="app-container">
      <AppHeader />
      <Container fluid="lg" className={containerClasses.join(' ')}>
        <Outlet />
      </Container>
      <Footer />
    </div>
  );
};

export default Layout;
