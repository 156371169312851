import { baseApi } from './baseApi';

export const usersApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    updateCurrentUser: builder.mutation({
      query: (data) => ({
        url: '/v1/users/me',
        method: 'PATCH',
        body: data,
      }),
    }),
  }),
});

export const { useUpdateCurrentUserMutation } = usersApi;
