export const appConfig = {
  api: {
    host: process.env.REACT_APP_DATA_REMOVER_API_URL,
  },
  appName: process.env.REACT_APP_NAME,
  appVersion: '0.1.0',
  env: process.env.REACT_APP_ENV,
  ga4MeasurementId: process.env.REACT_APP_GOOGLE_GA4_MEASUREMENT_ID,
  logRocketEnabled: process.env.REACT_APP_LOG_ROCKET_ENABLED === 'true',
  maps: {
    apiKey: process.env.REACT_APP_MAPS_API_KEY,
  },
  stripe: {
    pricingTableId: process.env.REACT_APP_STRIPE_PRICING_TABLE_ID,
    publishableKey: process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY,
  },
  urls: {
    marketingWebSite: process.env.REACT_APP_URL_MARKETING_WEBSITE,
    howItWorks: process.env.REACT_APP_URL_HOW_IT_WORKS,
  },
};
