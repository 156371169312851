import AccountEditScreen from './screens/AccountEditScreen';
import AccountScreen from './screens/AccountScreen';
import ChangePasswordScreen from './screens/ChangePasswordScreen';
import CompleteProfileScreen from './screens/CompleteProfileScreen';
import DashboardScreen from './screens/DashboardScreen';
import ErrorScreen from './screens/ErrorScreen';
import FilteringRecordsScreen from './screens/FilteringRecordScreen';
import FilteringResultsScreen from './screens/FilteringResultsScreen';
import FreeScanScreen from './screens/FreeScan';
import Layout from './screens/Layout';
import LogRocket from 'logrocket';
import LoginScreen from './screens/LoginScreen';
import ProfileScreen from './screens/ProfileScreen';
import { ProtectedRoute } from './components/ProtectedRoute';
import React from 'react';
import RequestPasswordResetScreen from './screens/RequestPasswordResetScreen';
import ResetPasswordScreen from './screens/ResetPasswordScreen';
import SignupScreen from './screens/SignupScreen';
import StartScreen from './screens/StartScreen';
import { appConfig } from './app/config';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

// These screens are used for the Pricing table
// we are not using the pricing table at the moment
// leaving here as most likely we will need this in the future
// import CheckoutCompleteScreen from './screens/CheckoutScreenComplete';
// import CheckoutScreen from './screens/CheckoutScreen';

if (appConfig.logRocketEnabled) {
  LogRocket.init('cnqdhz/data-remover', {
    release: appConfig.appVersion,
  });
}

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />} errorElement={<ErrorScreen />}>
          <Route index element={<Navigate to="/dashboard" />} />
          <Route
            path="/account"
            element={
              <ProtectedRoute>
                <AccountScreen />
              </ProtectedRoute>
            }
          />
          <Route
            path="/account/edit"
            element={
              <ProtectedRoute>
                <AccountEditScreen />
              </ProtectedRoute>
            }
          />
          <Route
            path="/account/change-password"
            element={
              <ProtectedRoute>
                <ChangePasswordScreen />
              </ProtectedRoute>
            }
          />

          {/* These screens are used in the pricing table
            <Route
            path="/checkout"
            element={
              <ProtectedRoute>
                <CheckoutScreen />
              </ProtectedRoute>
            }
          />
          <Route
            path="/checkout-complete"
            element={
              <ProtectedRoute>
                <CheckoutCompleteScreen />
              </ProtectedRoute>
            }
          /> */}
          <Route
            path="/complete-profile"
            element={
              <ProtectedRoute>
                <CompleteProfileScreen />
              </ProtectedRoute>
            }
          />
          <Route
            path="/dashboard/*"
            element={
              <ProtectedRoute>
                <DashboardScreen />
              </ProtectedRoute>
            }
          />
          <Route
            path="/filtering-results"
            element={
              <ProtectedRoute>
                <FilteringResultsScreen />
              </ProtectedRoute>
            }
          />
          <Route
            path="/filtering-records"
            element={
              <ProtectedRoute>
                <FilteringRecordsScreen />
              </ProtectedRoute>
            }
          />
          <Route path="/free-scan" element={<FreeScanScreen />} />
          <Route path="/login" element={<LoginScreen />} />
          <Route path="/free-trial-signup" element={<SignupScreen />} />
          <Route
            path="/profile"
            element={
              <ProtectedRoute>
                <ProfileScreen />
              </ProtectedRoute>
            }
          />
          <Route
            path="/request-password-reset"
            element={<RequestPasswordResetScreen />}
          />
          <Route
            path="/reset-password/:token?"
            element={<ResetPasswordScreen />}
          />
          <Route path="/signup" element={<StartScreen />} />
          <Route path="/start" element={<StartScreen />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
