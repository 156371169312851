import { baseApi } from './baseApi';

export const ANON_PROFILE_DATA_KEY = 'anonymousProfileData';

export const anonymousProfileApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    completeProfile: builder.mutation({
      query: (profileId) => ({
        url: `/v1/anonymous-profiles/${profileId}/complete`,
        method: 'POST',
      }),
    }),
    createAnonymousProfile: builder.mutation({
      query: (data) => ({
        url: '/v1/anonymous-profiles',
        method: 'POST',
        body: data,
      }),
      transformResponse: (data) => {
        localStorage.removeItem(ANON_PROFILE_DATA_KEY); // to be removed
        if (data) {
          localStorage.setItem(ANON_PROFILE_DATA_KEY, JSON.stringify(data));
        }

        return data;
      },
    }),
    createAnonymousScan: builder.mutation({
      query: (profileId) => ({
        url: `/v1/anonymous-profiles/${profileId}/scans`,
        method: 'POST',
      }),
    }),
    getAnonymousScans: builder.query({
      query: (profileId) => ({
        url: `/v1/anonymous-profiles/${profileId}/scans`,
      }),
    }),
    getAnonymousProfileCurrentStats: builder.query({
      query: (profileId) => ({
        url: `/v1/anonymous-profiles/${profileId}/current-stats`,
      }),
    }),
    getAnonymousScanResults: builder.query({
      query: (profileId) => ({
        url: `/v1/anonymous-profiles/${profileId}/scan-results`,
      }),
    }),
  }),
});

export const getLocalAnonymousProfileData = () => {
  const data = localStorage.getItem(ANON_PROFILE_DATA_KEY);

  try {
    return JSON.parse(data);
  } catch (e) {
    localStorage.removeItem(ANON_PROFILE_DATA_KEY);
    return null;
  }
};

export const deleteLocalAnonymousProfileData = () => {
  localStorage.removeItem(ANON_PROFILE_DATA_KEY);
};

export const {
  useCompleteProfileMutation,
  useCreateAnonymousProfileMutation,
  useCreateAnonymousScanMutation,
  useGetAnonymousProfileCurrentStatsQuery,
  useGetAnonymousScanResultsQuery,
  useGetAnonymousScansQuery,
} = anonymousProfileApi;
