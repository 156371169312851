import { appConfig } from '../../app/config';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const ACCESS_TOKEN_KEY = 'accessToken';

export const baseApi = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: appConfig.api.host,
    prepareHeaders: (headers, { _getState }) => {
      const token = localStorage.getItem(ACCESS_TOKEN_KEY);

      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: [],
  endpoints: () => ({}),
});
