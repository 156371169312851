import React from 'react';
const MapMarker = () => {
  return (
    <div
      style={{
        width: '22px',
        height: '22px',
        borderRadius: '100%',
        background: '#DD7003',
        border: '7px solid #090909',
      }}
      className={'google-map-marker'}></div>
  );
};
export default MapMarker;
