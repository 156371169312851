import ProfileDisplayEdit from '../components/ProfileDisplayEdit';
import React from 'react';
import { useDocumentTitle } from '../hooks/useDocumentTitle';
import { useGetCurrentUserQuery } from '../redux/api/authApi';
import { Col, Row, Spinner } from 'react-bootstrap';

const ProfileScreen = () => {
  const { data: currentUser, isLoading: isLoadingUser } =
    useGetCurrentUserQuery();

  const profileId =
    currentUser?.externalProfileIds?.[0] || currentUser?.anonymousProfileId;

  useDocumentTitle('Profile');

  if (isLoadingUser) {
    return (
      <React.Fragment>
        <h1 className="title">Profile</h1>
        <Row>
          <Col>
            <Spinner animation="border">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </Col>
        </Row>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <h1 className="title">Profile</h1>
      <p className="sub-header">
        Including more information below will lead to improved results in the
        removal process.
      </p>
      <div className="grid row">
        <ProfileDisplayEdit profileId={profileId} />
      </div>
    </React.Fragment>
  );
};

export default ProfileScreen;
