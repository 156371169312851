import Button from 'react-bootstrap/Button';
import { ErrorMessage } from '@hookform/error-message';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import React from 'react';
import { useChangePasswordMutation } from '../redux/api/authApi';
import { useDocumentTitle } from '../hooks/useDocumentTitle';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import { Alert, Row } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';

const ChangePasswordScreen = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm();

  const navigate = useNavigate();

  const [showComplete, setShowComplete] = useState(false);

  const [changePassword, { isLoading: isUpdating }] =
    useChangePasswordMutation();

  const submitForm = async (data) => {
    setShowComplete(false);
    const response = await changePassword(data);

    if (response?.error) {
      let errorMessages = 'An Error occurred';
      if (Array.isArray(response?.error?.data?.message)) {
        errorMessages = [
          ...new Set(response?.error?.data?.message.flat()),
        ].join('\n');
      } else if (response?.error?.data?.message) {
        errorMessages = response?.error?.data?.message;
      }

      setError('currentPassword', {
        type: 'custom',
        message: errorMessages,
      });
    } else {
      setShowComplete(true);
    }
  };

  useDocumentTitle('Change Password');

  return (
    <Form onSubmit={handleSubmit(submitForm)} className="m-3">
      <Row>
        <h2 className="mb-3">Change password</h2>
        {showComplete && (
          <Form.Group>
            <Alert variant={'success'}>
              Your password has been changed. Go to the{' '}
              <Link to="/account">Account</Link> or{' '}
              <Link to="/dashboard">Dashboard</Link> screen.
            </Alert>
          </Form.Group>
        )}
        {!showComplete && (
          <React.Fragment>
            <Form.Group>
              <ErrorMessage
                errors={errors}
                name={'currentPassword'}
                render={({ message }) => (
                  <Alert variant={'danger'}>{message}</Alert>
                )}
              />
              <FloatingLabel label="Current Password" className="mb-3">
                <Form.Control
                  {...register('currentPassword', {
                    required: 'Current Password is required',
                    disabled: showComplete || isUpdating,
                  })}
                  type="password"
                  placeholder="Current Password"
                />
              </FloatingLabel>
            </Form.Group>
            <Form.Group>
              <ErrorMessage
                errors={errors}
                name={'newPassword'}
                render={({ message }) => (
                  <Alert variant={'danger'}>{message}</Alert>
                )}
              />
              <FloatingLabel label="New Password" className="mb-3">
                <Form.Control
                  {...register('newPassword', {
                    required: 'New Password is required',
                    disabled: showComplete || isUpdating,
                  })}
                  type="password"
                  placeholder="New Password"
                />
              </FloatingLabel>
            </Form.Group>
            <Form.Group>
              <ErrorMessage
                errors={errors}
                name={'newPasswordConfirmation'}
                render={({ message }) => (
                  <Alert variant={'danger'}>{message}</Alert>
                )}
              />
              <FloatingLabel label="Confirm New Password" className="mb-3">
                <Form.Control
                  {...register('newPasswordConfirmation', {
                    required: 'New Confirmation Password is required',
                    disabled: showComplete || isUpdating,
                  })}
                  type="password"
                  placeholder="Confirm New Password"
                />
              </FloatingLabel>
            </Form.Group>
            <Form.Group>
              <Button
                variant="primary"
                type="submit"
                disabled={showComplete || isUpdating}>
                Change Password
              </Button>
              <Button
                variant="secondary"
                disabled={showComplete || isUpdating}
                onClick={() => navigate('/account')}>
                Cancel
              </Button>
            </Form.Group>
          </React.Fragment>
        )}
      </Row>
    </Form>
  );
};

export default ChangePasswordScreen;
