import Button from 'react-bootstrap/Button';
import { ErrorMessage } from '@hookform/error-message';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import { Link } from 'react-router-dom';
import React from 'react';
import { useDocumentTitle } from '../hooks/useDocumentTitle';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useResetPasswordMutation } from '../redux/api/authApi';
import { useState } from 'react';
import { Alert, Row } from 'react-bootstrap';

const ResetPasswordScreen = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm();

  const [showComplete, setShowComplete] = useState(false);

  const { token } = useParams();

  const [resetPassword, { isLoading: isResetting }] =
    useResetPasswordMutation();

  const submitForm = async (data) => {
    setShowComplete(false);
    const response = await resetPassword(data);

    if (response?.error) {
      let errorMessages = 'An Error occurred';
      if (Array.isArray(response?.error?.data?.message)) {
        errorMessages = [
          ...new Set(response?.error?.data?.message.flat()),
        ].join('\n');
      } else if (response?.error?.data?.message) {
        errorMessages = response?.error?.data?.message;
      }

      setError('password', {
        type: 'custom',
        message: errorMessages,
      });
    } else {
      setShowComplete(true);
    }
  };

  useDocumentTitle('Reset Password');

  return (
    <Form onSubmit={handleSubmit(submitForm)} className="m-3">
      <Row>
        <h2>Reset your password</h2>
        {showComplete && (
          <Form.Group>
            <Alert variant={'success'}>
              Your password has been reset. Go to the{' '}
              <Link to="/login">Login</Link> screen and log in with new
              password.
            </Alert>
          </Form.Group>
        )}
        {!token && (
          <Form.Group>
            <Alert variant={'danger'}>
              Invalid link. Go to <Link to="/login">Login</Link>
            </Alert>
          </Form.Group>
        )}
        {token && (
          <React.Fragment>
            <Form.Group>
              <ErrorMessage
                errors={errors}
                name={'password'}
                render={({ message }) => (
                  <Alert variant={'danger'}>{message}</Alert>
                )}
              />
              <FloatingLabel label="Password" className="mb-3">
                <Form.Control
                  {...register('password', {
                    required: 'Password is required',
                    disabled: showComplete || isResetting,
                  })}
                  type="password"
                  placeholder="Password"
                />
              </FloatingLabel>
            </Form.Group>
            <Form.Group>
              <ErrorMessage
                errors={errors}
                name={'passwordConfirmation'}
                render={({ message }) => (
                  <Alert variant={'danger'}>{message}</Alert>
                )}
              />
              <FloatingLabel label="Confirm Password" className="mb-3">
                <Form.Control
                  {...register('passwordConfirmation', {
                    required: 'Confirmation Password is required',
                    disabled: showComplete || isResetting,
                  })}
                  type="password"
                  placeholder="Confirm Password"
                />
              </FloatingLabel>
            </Form.Group>
            <input {...register('token', { value: token })} type="hidden" />
            <Form.Group>
              <Button
                variant="primary"
                type="submit"
                disabled={showComplete || isResetting}>
                Reset Password
              </Button>
            </Form.Group>
          </React.Fragment>
        )}
      </Row>
    </Form>
  );
};

export default ResetPasswordScreen;
