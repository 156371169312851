export const getAddresses = (scanResults = []) => {
  return scanResults
    .filter((n) => n.status !== 'removed')
    .flatMap((n) => n.addresses)
    .filter((n) => n.geo?.location)
    .map((n) => ({
      value: `${n.geo.location.lat}_${n.geo.location.lng}`,
      type: 'location',
      ...n,
    }))
    .reduce((acc, curr) => {
      const item = acc.find((n) => n.value === curr.value);
      if (!item) {
        acc.push({ ...curr, count: 1 });
      } else {
        item.count += 1;
      }
      return acc;
    }, []);
};
