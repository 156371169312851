import { AppLogoSmallGrey } from '../../assets';
import React from 'react';
import { AtSign, MapPin, Phone, Users } from 'lucide-react';

export const DashBoardCardIcon = ({ cardType }) => {
  switch (cardType) {
    case 'email':
      return <AtSign />;
    case 'location':
      return <MapPin />;
    case 'phone':
      return <Phone />;
    case 'relative':
      return <Users />;
    case 'cta':
      return <img src={AppLogoSmallGrey} alt="" />;
    case 'monitoring':
      return <img src={AppLogoSmallGrey} alt="" />;
    default:
      return null;
  }
};
