import { Col } from 'react-bootstrap';
import ScanResultCard from '../../components/ScanResultCard';
import { useGetCurrentUserQuery } from '../../redux/api/authApi';
import { useGetScanResultsQuery } from '../../redux/api/profileApi';
import { useInterval } from '../../hooks/useInterval';
import React, { useState } from 'react';

const TypeItem = ({ item, profileId, section }) => {
  const {
    data: scanResults = [],
    isLoading: isLoadingScans,
    refetch: refetchScanResultCard,
  } = useGetScanResultsQuery(profileId);
  // console.log('scan results:', scanResults);

  const { data: currentUser, isLoading: isLoadingUser } =
    useGetCurrentUserQuery();

  const [showRecords, setShowRecords] = useState(true);

  useInterval(
    () => {
      refetchScanResultCard();
    },
    scanResults?.length > 1 ? null : 100
  );

  let status = currentUser?.hasActiveSubscription ? 'in-progress' : 'new';
  const statusLabels = [
    {
      key: 'new',
      label: 'New',
    },
    {
      key: 'in-progress',
      label: 'In Progress',
    },
    {
      key: 'total-removed',
      label: 'Removed',
    },
    {
      key: 'some-removed',
      label: `Removed ${item.counts.removed}/${item.counts.total}`,
    },
  ];
  if (item.counts.removed === item.counts.total) {
    status = 'total-removed';
  }
  if (item.counts.removed > 0 && item.counts.removed < item.counts.total) {
    status = 'some-removed';
  }

  const statusLabel = statusLabels.find((n) => n.key === status)?.label;

  if (isLoadingScans || isLoadingUser) {
    return <React.Fragment></React.Fragment>;
  }

  return (
    <Col md={{ span: 12 }} key={item.value} className={'section-types-item'}>
      {section.key !== 'all' && (
        <div
          className="section-types-item-header"
          tabIndex={1}
          onClick={() => setShowRecords(!showRecords)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              setShowRecords(!showRecords);
            }
          }}
          aria-label={'Row for ' + item.value}>
          <span className="name">{item.value}</span>
          <div className={`status-badge status-${status}`}>{statusLabel}</div>
        </div>
      )}
      <div
        className={`section-types-records ${showRecords ? 'is-active' : ''}`}>
        <div className="results-table">
          <table>
            <tbody>
              {item.records.map((record, idx) => {
                const scanResult = scanResults.find(
                  (n) => n.id === record.scanResultId
                );
                return <ScanResultCard key={idx} scanResult={scanResult} />;
              })}
            </tbody>
          </table>
        </div>
      </div>
    </Col>
  );
};

export default TypeItem;
