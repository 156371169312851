import Button from 'react-bootstrap/Button';
import { ErrorMessage } from '@hookform/error-message';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import { Link } from 'react-router-dom';
import React from 'react';
import ReactGA from 'react-ga4';
import { useDocumentTitle } from '../hooks/useDocumentTitle';
import { useForm } from 'react-hook-form';
import {
  ACCESS_TOKEN_KEY,
  useGetCurrentUserQuery,
  useLoginMutation,
} from '../redux/api/authApi';
import { Alert, Col, Row } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';

const LoginScreen = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm();
  const navigate = useNavigate();
  const location = useLocation();

  const { data: currentUser } = useGetCurrentUserQuery();

  if (currentUser) {
    navigate('/');
  }

  const [login, { isLoading: isLoggingIn }] = useLoginMutation();

  const submitForm = async (data) => {
    const response = await login(data);

    if (response?.error) {
      let errorMessages = 'Login failed';
      if (Array.isArray(response?.error?.data?.message)) {
        errorMessages = [
          ...new Set(response?.error?.data?.message.flat()),
        ].join('\n');
      } else if (response?.error?.data?.message) {
        errorMessages = response?.error?.data?.message;
      }

      setError('email', {
        type: 'custom',
        message: errorMessages,
      });
    } else {
      ReactGA.gtag('event', 'login');

      if (localStorage.getItem(ACCESS_TOKEN_KEY)) {
        const navigateUrl = location.state?.from?.pathname || '/dashboard';
        navigate(navigateUrl);
      }
    }
  };

  useDocumentTitle('Login');

  return (
    <Row>
      <Col>
        <Form onSubmit={handleSubmit(submitForm)} className="m-3">
          <Row>
            <h1>Login</h1>
            <Form.Group>
              <ErrorMessage
                errors={errors}
                name={'email'}
                render={({ message }) => (
                  <Alert variant={'danger'}>{message}</Alert>
                )}
              />
              <FloatingLabel label="Email address" className="mb-3">
                <Form.Control
                  {...register('email', { required: 'Email is required' })}
                  placeholder="Email address"
                />
              </FloatingLabel>
            </Form.Group>
            <Form.Group>
              <ErrorMessage
                errors={errors}
                name={'password'}
                render={({ message }) => (
                  <Alert variant={'danger'}>{message}</Alert>
                )}
              />
              <FloatingLabel label="Password" className="mb-3">
                <Form.Control
                  {...register('password', {
                    required: 'Password is required',
                  })}
                  type="password"
                  placeholder="Password"
                />
              </FloatingLabel>
            </Form.Group>
            <Form.Group>
              <Button variant="primary" type="submit" disabled={isLoggingIn}>
                Login
              </Button>
            </Form.Group>
            <Form.Group>
              <Link to="/request-password-reset">Forgot Password</Link>
            </Form.Group>
            <Form.Group>
              New to InfoRemover? <Link to="/free-trial-signup">Sign Up</Link>
            </Form.Group>
          </Row>
        </Form>
      </Col>
    </Row>
  );
};

export default LoginScreen;
