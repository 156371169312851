import { baseApi } from './baseApi';

export const stripeApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    createCheckoutSession: builder.mutation({
      query: (couponCode) => ({
        url: '/v1/stripe/create-checkout-session',
        method: 'POST',
        body: { couponCode },
      }),
    }),
    createCheckoutPromoSession: builder.mutation({
      query: (PromoSessionInput) => ({
        url: '/v1/stripe/create-checkout-promo-session',
        method: 'POST',
        body: PromoSessionInput,
      }),
    }),
    createPortalSession: builder.mutation({
      query: () => ({
        url: '/v1/stripe/manage',
        method: 'POST',
      }),
    }),
    getCheckoutSession: builder.query({
      query: (sessionId) => ({
        url: `/v1/stripe/checkout-sessions/${sessionId}`,
      }),
    }),
  }),
});

export const {
  useCreateCheckoutSessionMutation,
  useCreateCheckoutPromoSessionMutation,
  useCreatePortalSessionMutation,
  useGetCheckoutSessionQuery,
} = stripeApi;
