import GoogleMapMarker from './GoogleMapMarker';
import GoogleMapReact from 'google-maps-react-markers';
import MapStyles from './GoogleMapStyles';
import { appConfig } from '../../app/config';
import React, { useRef } from 'react';

const Map = ({ addresses = [] }) => {
  const mapRef = useRef(null);

  const onGoogleApiLoaded = ({ map }) => {
    mapRef.current = map;
  };

  const defaultProps = {
    center: {
      lat: 34.66609,
      lng: -95.184683,
    },
    zoom: 3,
    styles: MapStyles,
  };
  return (
    <div className={'dashboard-map-container'}>
      <GoogleMapReact
        apiKey={appConfig.maps.apiKey}
        defaultCenter={defaultProps.center}
        onGoogleApiLoaded={onGoogleApiLoaded}
        defaultZoom={defaultProps.zoom}
        options={defaultProps}>
        {addresses.map((n, i) => (
          <GoogleMapMarker
            key={i}
            address={n}
            lat={n.geo.location.lat}
            lng={n.geo.location.lng}
          />
        ))}
      </GoogleMapReact>
    </div>
  );
};

export default Map;
