import LogRocket from 'logrocket';
import { appConfig } from '../../app/config';
import { baseApi } from './baseApi';
import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const ACCESS_TOKEN_KEY = 'accessToken';

export const authApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    changePassword: builder.mutation({
      query: (data) => ({
        url: '/v1/auth/change-password',
        method: 'POST',
        body: data,
      }),
    }),
    getCurrentUser: builder.query({
      query: () => ({ url: '/v1/users/me' }),
      transformResponse: (data) => {
        LogRocket.identify(data.id, {
          name: data.firstName + ' ' + data.lastName,
          email: data.email,
          subscriptionStatus: data.subscriptionStatus,
        });

        data.hasActiveSubscription = [
          'trialing',
          'active',
          'past_due',
          'unpaid',
        ].includes(data.subscriptionStatus);

        return data;
      },
    }),
    login: builder.mutation({
      async queryFn(credentials, queryApi, _extraOptions, _fetchWithBQ) {
        localStorage.removeItem(ACCESS_TOKEN_KEY);

        const query = fetchBaseQuery({ baseUrl: appConfig.api.host });

        const response = await query(
          {
            url: '/v1/auth/login',
            method: 'POST',
            body: credentials,
          },
          queryApi
        );

        if (response?.data?.accessToken) {
          localStorage.setItem(ACCESS_TOKEN_KEY, response.data.accessToken);
          // Force a current user request after login
          // queryApi.dispatch(
          // authApi.endpoints.getCurrentUser.initiate()
          // ); to be uncommented
        }
        return response;
      },
    }),
    logOut: builder.mutation({
      queryFn: (_arg, queryApi, _extraOptions, _fetchWithBQ) => {
        // Reset Redux state on logout
        queryApi.dispatch(authApi.util.resetApiState());

        // Remove JWT and COUPON_CODE from local storage
        localStorage.removeItem(ACCESS_TOKEN_KEY);
        localStorage.removeItem('COUPON_CODE');
        localStorage.removeItem('id');

        return { data: null };
      },
    }),
    register: builder.mutation({
      query: (data) => ({
        url: '/v1/auth/register',
        method: 'POST',
        body: data,
      }),
    }),
    requestPasswordReset: builder.mutation({
      query: (data) => ({
        url: '/v1/auth/request-password-reset',
        method: 'POST',
        body: data,
      }),
    }),
    resetPassword: builder.mutation({
      query: (data) => ({
        url: '/v1/auth/reset-password',
        method: 'POST',
        body: data,
      }),
    }),
  }),
});

export const {
  useChangePasswordMutation,
  useGetCurrentUserQuery,
  useLoginMutation,
  useLogOutMutation,
  useRegisterMutation,
  useRequestPasswordResetMutation,
  useResetPasswordMutation,
} = authApi;
