import ProfileDisplayEdit from '../components/ProfileDisplayEdit';
import toTitleCase from '../app/lib/toTitleCase';
import { useCompleteProfileMutation } from '../redux/api/anonymousProfile';
import { useDocumentTitle } from '../hooks/useDocumentTitle';
import { useGetCurrentUserQuery } from '../redux/api/authApi';
import { useState } from 'react';
import { Alert, Button, Col, Row, Spinner } from 'react-bootstrap';
import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

const CompleteProfileScreen = () => {
  const navigate = useNavigate();
  const {
    data: currentUser,
    isLoading: isLoadingUser,
    refetch: refetchCurrentUser,
  } = useGetCurrentUserQuery();

  const [searchParams, _setSearchParams] = useSearchParams();
  const stripeSessionId = searchParams.get('session_id');

  const profileId = currentUser?.anonymousProfileId;

  useEffect(() => {
    if (
      !currentUser?.anonymousProfileId &&
      currentUser?.externalProfileIds?.[0]
    ) {
      // navigate('/dashboard');
      navigate('/filtering-records');
    }
  }, [
    currentUser?.externalProfileIds,
    currentUser?.anonymousProfileId,
    navigate,
  ]);

  const [saveErrorMessage, setSaveErrorMessage] = useState(null);

  const [
    completeProfile,
    { isLoading: isCompletingProfile, isSuccess: isProfileCompleted },
  ] = useCompleteProfileMutation();
  // const [createScan, { isSuccess: isScanCreated }] =
  //   useCreateAnonymousScanMutation();

  useDocumentTitle('Complete Profile');

  const handleVerify = async (e) => {
    e.preventDefault();
    setSaveErrorMessage(null);

    const response = await completeProfile(profileId);

    if (response?.error) {
      const onRepErrors = response?.error?.data?.errors;

      let errorMessages = 'An Error has occurred. Please try again.';
      if (onRepErrors) {
        // `... new Set()` removes duplicate error messages
        errorMessages = [...new Set(Object.values(onRepErrors).flat())].join(
          '\n'
        );
      } else if (response?.error?.data?.message) {
        if (Array.isArray(response?.error?.data?.message)) {
          errorMessages = [
            ...new Set(response?.error?.data?.message.flat()),
          ].join('\n');
        } else {
          errorMessages = response?.error?.data?.message;
        }
      }

      setSaveErrorMessage(errorMessages);
    } else {
      await refetchCurrentUser();
      navigate('/filtering-records');
      // navigate('/filtering-results');
      // const userID = localStorage.getItem('id');
      // createScan(userID);
      // navigate('/free-scan');
    }
  };

  // useEffect(() => {
  //   if (isScanCreated) {
  //     navigate('/free-scan');
  //   }
  // }, [isScanCreated, navigate]);

  if (isLoadingUser) {
    return (
      <React.Fragment>
        <Row>
          <Col>
            <h1 className="title">Complete Profile</h1>
          </Col>
        </Row>
        <Row>
          <Col>
            <Spinner animation="border">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </Col>
        </Row>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      {stripeSessionId && (
        <Row>
          <Col>
            <Alert variant="success">
              Thank you, {toTitleCase(currentUser.firstName)}, for subscribing
              to InfoRemover!
            </Alert>
          </Col>
        </Row>
      )}
      <Row>
        <Col>
          <h1 className="title">Last Step!</h1>
          <h2>Complete and Activate Your Profile</h2>
          {saveErrorMessage && (
            <Alert variant="danger">{saveErrorMessage}</Alert>
          )}
          <p>
            Complete your profile to activate the monthly monitoring and removal
            service.
          </p>
          <p>
            Providing additional information will help us locate and remove your
            personal information more effectively. Please provide your birth
            date and any other relevant information, such as additional names
            (including maiden names), previous and current addresses, and phone
            numbers. Including nicknames or shortened versions of your name can
            also be helpful.
          </p>
          <p>
            When you&apos;re finished, click &quot;Done&quot; and we&apos;ll get
            started!
          </p>
        </Col>
      </Row>
      <ProfileDisplayEdit profileId={profileId} />
      <p></p>
      <Button
        variant="primary"
        onClick={handleVerify}
        disabled={isCompletingProfile || isProfileCompleted}>
        Done
      </Button>
    </React.Fragment>
  );
};

export default CompleteProfileScreen;
