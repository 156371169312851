import { ErrorMessage } from '@hookform/error-message';
import { profileJsonForUpdate } from './Profile/profileJsonForUpdate';
import { profileSections } from './Profile/profileSections';
import toTitleCase from '../app/lib/toTitleCase';
import { useForm } from 'react-hook-form';
import {
  Alert,
  Button,
  Col,
  FloatingLabel,
  Form,
  Modal,
  Offcanvas,
  OverlayTrigger,
  Row,
  Spinner,
  Tooltip,
} from 'react-bootstrap';
import { Edit3, PlusCircle, Trash2 } from 'lucide-react';
import React, { useState } from 'react';
import {
  useGetProfileQuery,
  useUpdateProfileMutation,
} from '../redux/api/profileApi';

const ProfileDisplayEdit = ({ profileId }) => {
  const [showInputPanel, setShowInputPanel] = useState(false);
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [removalError, setRemovalError] = useState(null);

  const [updateProfile, { isLoading: isUpdatingProfile }] =
    useUpdateProfileMutation();

  const {
    formState: { errors },
    handleSubmit,
    register,
    reset: resetInputForm,
    setFocus,
    setError,
  } = useForm();

  const {
    data: profileData,
    isLoading: isLoadingProfileData,
    isFetching: isFetchingProfileData,
    refetch: refetchProfile,
  } = useGetProfileQuery(profileId);

  const defaultInputSection = {
    type: '',
    itemIndex: null,
    section: {
      title: '',
      field: '',
      name: '',
      inputFields: [],
    },
    itemData: {},
  };

  const [inputSection, setInputSection] = useState(defaultInputSection);

  const handleInputClose = () => {
    setShowRemoveModal(false);
    setShowInputPanel(false);
    setRemovalError(null);

    setInputSection(defaultInputSection);
    resetInputForm();
  };

  const handleInputOpen = () => {
    setFocus(inputSection?.section.inputFields[0].field);
  };

  const addItem = (section) => {
    setInputSection({
      type: 'add',
      itemIndex: null,
      section: section,
      itemData: {},
    });

    setShowInputPanel(true);
  };

  const removeItem = (section, itemData, itemIndex) => {
    setInputSection({
      type: 'remove',
      itemIndex: itemIndex,
      section: section,
      itemData: itemData,
    });

    setShowRemoveModal(true);
  };

  const editField = (section, itemData) => {
    setInputSection({
      type: 'edit-field',
      itemIndex: null,
      section: section,
      itemData: itemData,
    });

    setShowInputPanel(true);
  };

  const editItem = (section, itemData, itemIndex) => {
    setInputSection({
      type: 'edit',
      itemIndex: itemIndex,
      section: section,
      itemData: itemData,
    });

    setShowInputPanel(true);
  };

  const panelTitle = (operationType) => {
    if (operationType === 'add') {
      return 'Add';
    }

    if (operationType === 'edit' || operationType === 'edit-field') {
      return 'Edit';
    }

    if (operationType === 'remove') {
      return 'Remove';
    }
  };

  const saveData = async (data) => {
    const updateProfileJson = profileJsonForUpdate(profileData);

    if (inputSection?.type === 'add') {
      updateProfileJson[inputSection.section.field].push(data);
    } else if (inputSection?.type === 'edit') {
      updateProfileJson[inputSection.section.field][inputSection.itemIndex] =
        data;
    } else if (inputSection?.type === 'edit-field') {
      updateProfileJson[inputSection.section.field] =
        data[inputSection.section.field];
    } else if (inputSection?.type === 'remove') {
      updateProfileJson[inputSection.section.field].splice(
        inputSection.itemIndex,
        1
      );
    }

    const response = await updateProfile({
      id: profileId,
      data: updateProfileJson,
    });

    if (response?.error) {
      const onRepErrors = response?.error?.data?.errors;

      let errorMessages = 'An Error has occurred. Please try again.';
      if (onRepErrors) {
        // `... new Set()` removes duplicate error messages
        errorMessages = [...new Set(Object.values(onRepErrors).flat())].join(
          '\n'
        );
      } else if (response?.error?.data?.message) {
        if (Array.isArray(response?.error?.data?.message)) {
          errorMessages = [
            ...new Set(response?.error?.data?.message.flat()),
          ].join('\n');
        } else {
          errorMessages = response?.error?.data?.message;
        }
      }

      if (inputSection?.type === 'remove') {
        setRemovalError(errorMessages);
      } else {
        // Set the error on the first field of the input section
        setError(inputSection?.section.inputFields[0].field, {
          type: 'custom',
          message: errorMessages,
        });
      }
    } else {
      setShowRemoveModal(false);
      setShowInputPanel(false);
      setRemovalError(null);
      setInputSection(defaultInputSection);

      resetInputForm();
      refetchProfile();
    }
  };

  const inputFormField = (inputField) => {
    if (inputField.field === 'state') {
      return (
        <Form.Select
          aria-label="State"
          {...register('state', {
            disabled: isUpdatingProfile,
            required:
              inputField?.required === true
                ? inputField.name + ' is required'
                : false,
            validate: inputField?.validate,
            value:
              inputSection?.type === 'edit'
                ? inputSection?.itemData?.[inputField.field]
                : null,
          })}
          placeholder="State">
          <option value="AL">Alabama</option>
          <option value="AK">Alaska</option>
          <option value="AZ">Arizona</option>
          <option value="AR">Arkansas</option>
          <option value="CA">California</option>
          <option value="CO">Colorado</option>
          <option value="CT">Connecticut</option>
          <option value="DE">Delaware</option>
          <option value="DC">District of Columbia</option>
          <option value="FL">Florida</option>
          <option value="GA">Georgia</option>
          <option value="HI">Hawaii</option>
          <option value="ID">Idaho</option>
          <option value="IL">Illinois</option>
          <option value="IN">Indiana</option>
          <option value="IA">Iowa</option>
          <option value="KS">Kansas</option>
          <option value="KY">Kentucky</option>
          <option value="LA">Louisiana</option>
          <option value="ME">Maine</option>
          <option value="MD">Maryland</option>
          <option value="MA">Massachusetts</option>
          <option value="MI">Michigan</option>
          <option value="MN">Minnesota</option>
          <option value="MS">Mississippi</option>
          <option value="MO">Missouri</option>
          <option value="MT">Montana</option>
          <option value="NE">Nebraska</option>
          <option value="NV">Nevada</option>
          <option value="NH">New Hampshire</option>
          <option value="NJ">New Jersey</option>
          <option value="NM">New Mexico</option>
          <option value="NY">New York</option>
          <option value="NC">North Carolina</option>
          <option value="ND">North Dakota</option>
          <option value="OH">Ohio</option>
          <option value="OK">Oklahoma</option>
          <option value="OR">Oregon</option>
          <option value="PA">Pennsylvania</option>
          <option value="RI">Rhode Island</option>
          <option value="SC">South Carolina</option>
          <option value="SD">South Dakota</option>
          <option value="TN">Tennessee</option>
          <option value="TX">Texas</option>
          <option value="UT">Utah</option>
          <option value="VT">Vermont</option>
          <option value="VA">Virginia</option>
          <option value="WA">Washington</option>
          <option value="WV">West Virginia</option>
          <option value="WI">Wisconsin</option>
          <option value="WY">Wyoming</option>
        </Form.Select>
      );
    }

    let fieldValue = null;
    if (inputSection?.type === 'edit') {
      fieldValue = inputSection?.itemData?.[inputField.field];
    } else if (inputSection?.type === 'edit-field') {
      fieldValue = inputSection?.itemData;
    }

    return (
      <Form.Control
        {...register(inputField.field, {
          disabled: isUpdatingProfile,
          required:
            inputField?.required === true
              ? inputField.name + ' is required'
              : false,
          validate: inputField?.validate,
          value: fieldValue,
        })}
        placeholder={inputField.name}
        type={inputField?.type || 'text'}
      />
    );
  };

  if (isLoadingProfileData || isFetchingProfileData) {
    return (
      <React.Fragment>
        <Row>
          <Col>
            <Spinner animation="border">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </Col>
        </Row>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <Row>
        <Col>
          <h2>
            {profileData?.firstName}{' '}
            {profileData?.middleName && profileData?.middleName + ' '}
            {profileData?.lastName}
          </h2>
        </Col>
      </Row>
      {profileSections.map((section) => {
        return Array.isArray(profileData?.[section.field]) === true ? (
          <Row key={section.field}>
            <Col>
              <Col>
                <h3
                  onClick={() => {
                    addItem(section);
                  }}>
                  {section.title}
                </h3>
                {section.desc && <h4>{section.desc}</h4>}
              </Col>
              <div className="icons">
                <OverlayTrigger
                  placement="bottom"
                  overlay={(props) => {
                    return (
                      <Tooltip id="button-tooltip" {...props}>
                        {`Add ${section.name}`}
                      </Tooltip>
                    );
                  }}>
                  <span
                    tabIndex={1}
                    className="icon"
                    aria-label={`Add ${section.title}`}
                    onClick={() => {
                      addItem(section);
                    }}
                    onKeyUp={(e) => {
                      if (e.key === 'Enter') {
                        addItem(section);
                      }
                    }}>
                    <PlusCircle />
                  </span>
                </OverlayTrigger>
              </div>
              {profileData?.[section.field]?.map((item, idx) => {
                return (
                  <Row key={idx}>
                    <Col className="m-p-0">
                      <div
                        className="profile-data-item"
                        onClick={() => {
                          editItem(section, item, idx);
                        }}>
                        {section.formatter ? section.formatter(item) : item}
                      </div>
                    </Col>
                    <div className="icons">
                      <OverlayTrigger
                        placement="bottom"
                        overlay={(props) => {
                          return (
                            <Tooltip id="button-tooltip" {...props}>
                              Edit Item
                            </Tooltip>
                          );
                        }}>
                        <span
                          tabIndex={1}
                          className="icon"
                          aria-label={`Edit ${section.formatter(item, true)}`}
                          onKeyUp={(e) => {
                            if (e.key === 'Enter') {
                              editItem(section, item, idx);
                            }
                          }}
                          onClick={() => {
                            editItem(section, item, idx);
                          }}>
                          <Edit3 />
                        </span>
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement="bottom"
                        overlay={(props) => {
                          return (
                            <Tooltip id="button-tooltip" {...props}>
                              Remove Item
                            </Tooltip>
                          );
                        }}>
                        <span
                          tabIndex={1}
                          className="icon"
                          aria-label={`Remove ${section.formatter(item, true)}`}
                          onKeyUp={(e) => {
                            if (e.key === 'Enter') {
                              removeItem(section, item, idx);
                            }
                          }}
                          onClick={() => {
                            removeItem(section, item, idx);
                          }}>
                          <Trash2 />
                        </span>
                      </OverlayTrigger>
                    </div>
                  </Row>
                );
              })}
            </Col>
          </Row>
        ) : (
          <Row key={section.field}>
            <Col>
              <h3
                onClick={() => {
                  editField(section, profileData?.[section.field]);
                }}>
                {section.title}
              </h3>
              <div
                onClick={() => {
                  editField(section, profileData?.[section.field]);
                }}
                className="profile-data-item">
                {section.formatter
                  ? section.formatter(profileData?.[section.field])
                  : profileData?.[section.field]}
              </div>
            </Col>
            <div className="icons">
              <OverlayTrigger
                placement="bottom"
                overlay={(props) => {
                  return (
                    <Tooltip id="button-tooltip" {...props}>
                      {`Edit ${section.title}`}
                    </Tooltip>
                  );
                }}>
                <span
                  tabIndex={1}
                  className="icon"
                  aria-label={`Edit ${section.title}`}
                  onKeyUp={(e) => {
                    if (e.key === 'Enter') {
                      editField(section, profileData?.[section.field]);
                    }
                  }}
                  onClick={() => {
                    editField(section, profileData?.[section.field]);
                  }}>
                  <Edit3 />
                </span>
              </OverlayTrigger>
            </div>
          </Row>
        );
      })}

      <Offcanvas
        show={showInputPanel}
        onEntered={handleInputOpen}
        placement="end"
        onHide={handleInputClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            {toTitleCase(panelTitle(inputSection?.type))}{' '}
            {inputSection?.section?.name}
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {isUpdatingProfile && (
            <Spinner animation="border">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          )}
          <Form onSubmit={handleSubmit(saveData)}>
            <Form.Group>
              {inputSection?.section?.inputFields?.map((inputField) => {
                return (
                  <React.Fragment key={inputField.name}>
                    <ErrorMessage
                      errors={errors}
                      name={inputField.field}
                      render={({ message }) => (
                        <Alert variant={'danger'}>{message}</Alert>
                      )}
                    />
                    <FloatingLabel label={inputField.name} className="mb-3">
                      {inputFormField(inputField)}
                    </FloatingLabel>
                  </React.Fragment>
                );
              })}
              <Button variant="primary" type="submit">
                Save
              </Button>
              <Button variant="secondary" onClick={handleInputClose}>
                Close
              </Button>
            </Form.Group>
          </Form>
        </Offcanvas.Body>
      </Offcanvas>

      <Modal show={showRemoveModal} onHide={handleInputClose}>
        <Modal.Header closeButton>
          <Modal.Title>Remove Item</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isUpdatingProfile && (
            <Spinner animation="border">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          )}
          {removalError && <Alert variant={'danger'}>{removalError}</Alert>}
          <p>
            Are you sure you want to remove this {inputSection?.section?.name}?
          </p>
          <p>
            {showRemoveModal &&
              inputSection?.section?.formatter(inputSection?.itemData, true)}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={saveData}>
            Delete
          </Button>
          <Button variant="secondary" onClick={handleInputClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export default ProfileDisplayEdit;
