import Button from 'react-bootstrap/Button';
import { ErrorMessage } from '@hookform/error-message';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import React from 'react';
import { useDocumentTitle } from '../hooks/useDocumentTitle';
import { useForm } from 'react-hook-form';
import { useGetCurrentUserQuery } from '../redux/api/authApi';
import { useNavigate } from 'react-router-dom';
import { useUpdateCurrentUserMutation } from '../redux/api/usersApi';
import { Alert, Row, Spinner } from 'react-bootstrap';

const AccountEditScreen = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm();

  const {
    data: currentUser,
    isSuccess: isCurrentUserSuccess,
    refetch: refetchCurrentUser,
  } = useGetCurrentUserQuery();

  const navigate = useNavigate();

  const [updateAccountInfo, { isLoading: isUpdating }] =
    useUpdateCurrentUserMutation();

  const submitForm = async (data) => {
    const response = await updateAccountInfo(data);

    if (response?.error) {
      let errorMessages = 'An Error occurred';
      if (Array.isArray(response?.error?.data?.message)) {
        errorMessages = [
          ...new Set(response?.error?.data?.message.flat()),
        ].join('\n');
      } else if (response?.error?.data?.message) {
        errorMessages = response?.error?.data?.message;
      }

      setError('firstName', {
        type: 'custom',
        message: errorMessages,
      });
    } else {
      await refetchCurrentUser();
      navigate('/account');
    }
  };

  useDocumentTitle('Update Account');

  if (!isCurrentUserSuccess) {
    return (
      <Spinner animation="border">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    );
  }

  return (
    <Form onSubmit={handleSubmit(submitForm)} className="m-3">
      <Row>
        <h2 className="mb-3">Update Account</h2>
        <React.Fragment>
          <Form.Group>
            <ErrorMessage
              errors={errors}
              name={'firstName'}
              render={({ message }) => (
                <Alert variant={'danger'}>{message}</Alert>
              )}
            />
            <FloatingLabel label="First Name" className="mb-3">
              <Form.Control
                {...register('firstName', {
                  required: 'First Name is required',
                  disabled: isUpdating,
                  value: currentUser?.firstName,
                })}
                placeholder="First Name"
              />
            </FloatingLabel>
          </Form.Group>
          <Form.Group>
            <ErrorMessage
              errors={errors}
              name={'lastName'}
              render={({ message }) => (
                <Alert variant={'danger'}>{message}</Alert>
              )}
            />
            <FloatingLabel label="Last Name" className="mb-3">
              <Form.Control
                {...register('lastName', {
                  required: 'Last Name is required',
                  disabled: isUpdating,
                  value: currentUser?.lastName,
                })}
                placeholder="Last Name"
              />
            </FloatingLabel>
          </Form.Group>
          <Form.Group>
            <ErrorMessage
              errors={errors}
              name={'email'}
              render={({ message }) => (
                <Alert variant={'danger'}>{message}</Alert>
              )}
            />
            <FloatingLabel label="Email address" className="mb-3">
              <Form.Control
                {...register('email', {
                  required: 'Email is required',
                  disabled: isUpdating,
                  value: currentUser?.email,
                })}
                placeholder="Email address"
                type="email"
              />
            </FloatingLabel>
          </Form.Group>
          <Form.Group>
            <ErrorMessage
              errors={errors}
              name={'currentPassword'}
              render={({ message }) => (
                <Alert variant={'danger'}>{message}</Alert>
              )}
            />
            <FloatingLabel label="Current Password" className="mb-3">
              <Form.Control
                {...register('currentPassword', {
                  required: 'Current Password is required',
                  disabled: isUpdating,
                })}
                type="password"
                placeholder="Current Password"
              />
            </FloatingLabel>
          </Form.Group>
          <Form.Group>
            <Button variant="primary" type="submit" disabled={isUpdating}>
              Save
            </Button>
            <Button
              variant="secondary"
              disabled={isUpdating}
              onClick={() => navigate('/account')}>
              Cancel
            </Button>
          </Form.Group>
        </React.Fragment>
      </Row>
    </Form>
  );
};

export default AccountEditScreen;
