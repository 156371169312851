import { Button } from 'react-bootstrap';
import { DashBoardCardIcon } from './Dashboard/DashboardCardIcon';
import { useInterval } from '../hooks/useInterval';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const DashboardCard = ({ payload = null, delay = 200 }) => {
  const navigate = useNavigate();
  const [isActive, setIsActive] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const multi = Array.isArray(payload);
  const currentItem = multi ? payload[activeIndex % payload.length] : payload;
  const cardType = currentItem.type;
  const location = useLocation();

  useEffect(() => {
    setTimeout(() => {
      setIsActive(true);
    }, 10);
  }, []);

  const minDelay = 2000;
  const maxDelay = 5000;

  useInterval(() => {
    setActiveIndex(activeIndex + 1);
  }, Math.floor(Math.random() * (maxDelay - minDelay)) + minDelay);

  return (
    <div
      className={`dashboard-card type-${cardType} ${
        isActive ? 'is-active' : ''
      }`}
      style={{ '--animation-delay': `${delay}ms` }}>
      <div className="card-top">
        {cardType !== 'stat' && (
          <div className="card-icon">
            <DashBoardCardIcon cardType={cardType} />
          </div>
        )}
        {cardType === 'stat' && (
          <div className={'card-donut'}>
            <div className="donut-content">
              {`${currentItem.statusValue}/${currentItem.statusTotal}`}
            </div>
            <svg width="113" height="113" xmlns="http://www.w3.org/2000/svg">
              <g>
                <title>Layer 1</title>
                <circle
                  style={{
                    '--percentage': parseFloat(
                      currentItem.statusValue / currentItem.statusTotal,
                      2
                    ),
                  }}
                  className={`donut ${
                    currentItem.statusValue === 0 ? 'is-zero' : ''
                  }`}
                  r="49"
                  cy="56"
                  cx="56"
                  stroke="#69aff4"
                  fill="none"
                />
              </g>
            </svg>
          </div>
        )}
      </div>
      <div className="card-bottom">
        {cardType === 'stat' && (
          <React.Fragment>
            <h2>Total Profiles Removed</h2>
            <p>
              The total amount of profiles found and removed throughout the
              internet.
            </p>
          </React.Fragment>
        )}
        {cardType === 'relative' && (
          <React.Fragment>
            <h2>Do you know or are you related to this person?</h2>
            <span>{currentItem?.value}</span>
            <p>
              We found {multi ? payload.length : ''} people that might be
              related to you.{' '}
            </p>
          </React.Fragment>
        )}
        {cardType === 'location' && (
          <React.Fragment>
            <h2>Have you lived here?</h2>
            <span>{`${currentItem.city}, ${currentItem.state}`}</span>
            <p>
              We found {multi ? payload.length : ''} cities associated with your
              name.{' '}
            </p>
          </React.Fragment>
        )}
        {cardType === 'email' && (
          <React.Fragment>
            <h2>Do you recognize this email address?</h2>
            <span>{`${currentItem.value}`}</span>
            <p>
              We found {multi ? payload.length : ''} emails associated with your
              name.{' '}
            </p>
          </React.Fragment>
        )}
        {cardType === 'phone' && (
          <React.Fragment>
            <h2>Do you recognize this phone number?</h2>
            <span>{`${currentItem.value}`}</span>
            <p>
              We found {multi ? payload.length : ''} phone numbers associated
              with your name.{' '}
            </p>
          </React.Fragment>
        )}
        {cardType === 'cta' && (
          <React.Fragment>
            <h2>Let us monitor and remove your private information.</h2>
            {location.pathname !== '/filtering-records' && (
              <Button variant="primary" onClick={() => currentItem.action()}>
                See Your Results
              </Button>
            )}
          </React.Fragment>
        )}
        {cardType === 'monitoring' && (
          <React.Fragment>
            <h2>View the full monitoring report</h2>
            <p>Get the full breakdown by type and removal status</p>
            <Button variant="primary" onClick={() => navigate('/dashboard')}>
              View Reporting
            </Button>
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

export default DashboardCard;
