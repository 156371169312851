import Button from 'react-bootstrap/Button';
import { ErrorMessage } from '@hookform/error-message';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import { useDocumentTitle } from '../hooks/useDocumentTitle';
import { useForm } from 'react-hook-form';
import { useRequestPasswordResetMutation } from '../redux/api/authApi';
import { Alert, Row } from 'react-bootstrap';
import React, { useState } from 'react';

const RequestPasswordResetScreen = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm();

  const [showComplete, setShowComplete] = useState(false);

  const [requestPasswordReset, { isLoading: isRequestingReset }] =
    useRequestPasswordResetMutation();

  const submitForm = async (data) => {
    setShowComplete(false);
    const response = await requestPasswordReset(data);

    if (response?.error) {
      let errorMessages = 'An Error occurred';
      if (Array.isArray(response?.error?.data?.message)) {
        errorMessages = [
          ...new Set(response?.error?.data?.message.flat()),
        ].join('\n');
      } else if (response?.error?.data?.message) {
        errorMessages = response?.error?.data?.message;
      }

      setError('email', {
        type: 'custom',
        message: errorMessages,
      });
    } else {
      setShowComplete(true);
    }
  };

  useDocumentTitle('Reset Password');

  return (
    <Form onSubmit={handleSubmit(submitForm)} className="m-3">
      <Row>
        <h2>Reset your password</h2>
        {showComplete && (
          <Form.Group>
            <Alert variant={'success'}>
              Password reset email has been sent. Check your email for the link.
            </Alert>
          </Form.Group>
        )}
        <Form.Group>
          <Form.Text>
            Tell us the email address associated with your InfoRemover account,
            and we will send you an email with a link to reset your password.
          </Form.Text>
        </Form.Group>
        <Form.Group>
          <ErrorMessage
            errors={errors}
            name={'email'}
            render={({ message }) => (
              <Alert variant={'danger'}>{message}</Alert>
            )}
          />
          <FloatingLabel label="Email address" className="mb-3">
            <Form.Control
              {...register('email', {
                required: 'Email is required',
                disabled: showComplete || isRequestingReset,
              })}
              placeholder="Email address"
            />
          </FloatingLabel>
        </Form.Group>
        <Form.Group>
          <Button
            variant="primary"
            type="submit"
            disabled={showComplete || isRequestingReset}>
            Reset Password
          </Button>
        </Form.Group>
      </Row>
    </Form>
  );
};

export default RequestPasswordResetScreen;
