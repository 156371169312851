import { baseApi } from './baseApi';

export const profileApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getLatestScan: builder.query({
      query: (profileId) => ({
        url: `/v1/profiles/${profileId}/scans/latest`,
      }),
    }),
    getProfile: builder.query({
      query: (profileId) => ({ url: `/v1/profiles/${profileId}` }),
    }),
    getProfileCurrentStats: builder.query({
      query: (profileId) => ({
        url: `/v1/profiles/${profileId}/current-stats`,
      }),
    }),
    getScanResults: builder.query({
      query: (profileId) => ({
        url: `/v1/profiles/${profileId}/scan-results`,
      }),
    }),
    getScanResultsSummary: builder.query({
      query: (profileId) => ({
        url: `/v1/profiles/${profileId}/scan-results-summary`,
      }),
    }),
    getScans: builder.query({
      query: (profileId) => ({ url: `/v1/profiles/${profileId}/scans` }),
    }),
    updateProfile: builder.mutation({
      query: ({ id, data }) => ({
        url: `/v1/profiles/${id}`,
        method: 'PUT',
        body: data,
      }),
    }),
  }),
});

export const {
  useGetLatestScanQuery,
  useGetProfileCurrentStatsQuery,
  useGetProfileQuery,
  useGetScanResultsQuery,
  useGetScanResultsSummaryQuery,
  useGetScansQuery,
  useUpdateProfileMutation,
} = profileApi;
