import { appConfig } from '../app/config';
import { useEffect } from 'react';

export function useDocumentTitle(title) {
  useEffect(() => {
    if (title.length > 0) {
      document.title = `${title} | ${appConfig.appName}`;
    } else {
      document.title = appConfig.appName;
    }
  }, [title]);
}
