import React from 'react';
import { useGetCurrentUserQuery } from '../redux/api/authApi';
import { Navigate, useLocation } from 'react-router-dom';

export const ProtectedRoute = ({ children }) => {
  const query = useGetCurrentUserQuery();
  const { isSuccess, data, isLoading } = query;
  const location = useLocation();

  if ((!isSuccess || !data) && !isLoading) {
    return <Navigate to="/login" replace state={{ from: location }} />;
  }

  return children;
};
