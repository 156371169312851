import ScanResultsSummary from '../../components/ScanResultsSummary';
import { useGetCurrentUserQuery } from '../../redux/api/authApi';
import { useGetProfileCurrentStatsQuery } from '../../redux/api/profileApi';
import { useInRouterContext } from 'react-router-dom';
import { Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';

const Reporting = ({ profileId, statsTypes, scanResults }) => {
  const inContext = useInRouterContext();
  const [isActive, setIsActive] = useState(false);
  const { data: currentStats, isLoading } =
    useGetProfileCurrentStatsQuery(profileId);

  const { data: currentUser, isLoading: isLoadingUser } =
    useGetCurrentUserQuery();

  const userIsTrialing = currentUser?.subscriptionStatus === 'trialing';

  useEffect(() => {
    if (inContext === true) {
      setIsActive(true);
    }
  }, [inContext]);

  if (isLoading || isLoadingUser) {
    return null;
  }

  const displayStats = { ...currentStats };
  if (userIsTrialing) {
    displayStats['totalInProgress'] += displayStats.new;
    displayStats['optoutInProgress'] += displayStats.new;
    displayStats['new'] = 0;
  }

  const renderToolTips = {};
  statsTypes.forEach((status) => {
    renderToolTips[status.key] = (props) => {
      return (
        <Tooltip id="button-tooltip" {...props}>
          {status.tooltip}
        </Tooltip>
      );
    };
  });

  return (
    <div className={`reporting-container ${isActive ? 'is-active' : ''}`}>
      <Row className="stats-row">
        {statsTypes.map((n, _i) => (
          <OverlayTrigger
            key={n.key}
            placement="bottom"
            delay={{ show: 125, hide: 400 }}
            overlay={renderToolTips[n.key]}>
            <Col>
              <div
                className={`stats-box ${n.key}`}
                aria-label={'Status ' + n.text + ': ' + n.tooltip}>
                <div className="stat-value">{displayStats[n.key]}</div>
                <div className="stat-name">{n.text}</div>
              </div>
            </Col>
          </OverlayTrigger>
        ))}
      </Row>
      <ScanResultsSummary profileId={profileId} scanResults={scanResults} />
    </div>
  );
};

export default Reporting;
